/* Variables
/* ---------------------------------------------------------- */

:root {
    /* Colours */
    --blue: #0078d0;
    --green: #a4d037;
    --purple: #ad26b4;
    --yellow: #fecd35;
    --red: #f05230;
    --darkgrey: #15171a;
    --midgrey: #738a94;
    --lightgrey: #c5d2d9;
    --whitegrey: #e5eff5;
    --pink: #fa3a57;
    --brown: #a3821a;
    --darkmode: color-mod(var(--darkgrey) l(+2%));
    --bluishGreen: linear-gradient(90.11deg, #3364fa 0.09%, #25cf41 99.96%);
    --white: #ffffff;
    --dark-blue: #283560;
    --sky-blue: #3364fa;
    --black: #000000;
    --dark-grey: #333333;
    --dark-grey-2: #444444;
    --light-grey: #666666;
    --light-grey-2: #718096;
    --light-grey-3: #6e757e;
    --purple-2: #edf1f7;
    --green-2: #26d16b;
    --light-blue: #3364fa;
    --dark-blue-2: #182242;
    --dark-blue-3: #303b6a;
    --light-blue-2: #e1fbff;
    --dark-grey-3: #ecf0f3;
    --blue-500: #0083ff;
    --gray-900: #171923;
}

/* Reset
/* ---------------------------------------------------------- */

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
    margin: 0;
    padding: 0;
    border: 0;
    font: inherit;
    font-size: 100%;
    vertical-align: baseline;
}

body {
    line-height: 1;
}

ol,
ul {
    list-style: none;
}

blockquote,
q {
    quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
    content: "";
    content: none;
}

table {
    border-spacing: 0;
    border-collapse: collapse;
}

img {
    max-width: 100%;
}

html {
    box-sizing: border-box;
    font-family: sans-serif;

    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
    scroll-behavior: smooth;
}

*,
*:before,
*:after {
    box-sizing: inherit;
}

a {
    background-color: transparent;
}

a:active,
a:hover {
    outline: 0;
}

b,
strong {
    font-weight: bold;
}

i,
em,
dfn {
    font-style: italic;
}

h1 {
    margin: 0.67em 0;
    font-size: 2em;
}

small {
    font-size: 80%;
}

sub,
sup {
    position: relative;
    font-size: 75%;
    line-height: 0;
    vertical-align: baseline;
}

sup {
    top: -0.5em;
}

sub {
    bottom: -0.25em;
}

img {
    border: 0;
}

svg:not(:root) {
    overflow: hidden;
}

mark {
    background-color: #fdffb6;
}

code,
kbd,
pre,
samp {
    font-family: monospace, monospace;
    font-size: 1em;
}

button,
input,
optgroup,
select,
textarea {
    margin: 0;
    /* 3 */
    color: inherit;
    /* 1 */
    font: inherit;
    /* 2 */
}

button {
    overflow: visible;
    border: none;
}

button,
select {
    text-transform: none;
}

button,
html input[type="button"],
/* 1 */
input[type="reset"],
input[type="submit"] {
    cursor: pointer;
    /* 3 */

    -webkit-appearance: button;
    /* 2 */
}

button[disabled],
html input[disabled] {
    cursor: default;
}

button::-moz-focus-inner,
input::-moz-focus-inner {
    padding: 0;
    border: 0;
}

input {
    line-height: normal;
}

input:focus {
    outline: none;
}

input[type="checkbox"],
input[type="radio"] {
    box-sizing: border-box;
    /* 1 */
    padding: 0;
    /* 2 */
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
    height: auto;
}

input[type="search"] {
    box-sizing: content-box;
    /* 2 */

    -webkit-appearance: textfield;
    /* 1 */
}

input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
    -webkit-appearance: none;
}

legend {
    padding: 0;
    /* 2 */
    border: 0;
    /* 1 */
}

textarea {
    overflow: auto;
}

table {
    border-spacing: 0;
    border-collapse: collapse;
}

td,
th {
    padding: 0;
}

/* ==========================================================================
   Base styles: opinionated defaults
   ========================================================================== */

html {
    overflow-x: hidden;
    overflow-y: auto;
    /* font-size: 62.5%; */

    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

body {
    overflow-x: hidden;
    color: color-mod(var(--midgrey) l(-30%));
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
    font-size: 1.6rem;
    line-height: 1.6em;
    font-weight: 400;
    font-style: normal;
    letter-spacing: 0;
    text-rendering: optimizeLegibility;
    background: #fff;
    position: relative;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -moz-font-feature-settings: "liga" on;
}

::selection {
    text-shadow: none;
    background: color-mod(var(--blue) lightness(+30%));
}

hr {
    position: relative;
    display: block;
    width: 100%;
    margin: 2.5em 0 3.5em;
    padding: 0;
    height: 1px;
    border: 0;
    border-top: 1px solid color-mod(var(--lightgrey) l(+10%));
}

audio,
canvas,
iframe,
img,
svg,
video {
    vertical-align: middle;
}

fieldset {
    margin: 0;
    padding: 0;
    border: 0;
}

textarea {
    resize: vertical;
}

p,
ul,
ol,
dl,
blockquote {
    margin: 0 0 0 0;
}

ol,
ul {
    padding-left: 1.3em;
    padding-right: 1.5em;
}

ol ol,
ul ul,
ul ol,
ol ul {
    margin: 0.5em 0 1em;
}

ul {
    list-style: disc;
}

ol {
    list-style: decimal;
}

ul,
ol {
    max-width: 100%;
}

li {
    margin: 0.5em 0;
    padding-left: 0.3em;
    line-height: 1.6em;
}

dt {
    float: left;
    margin: 0 20px 0 0;
    width: 120px;
    color: var(--darkgrey);
    font-weight: 500;
    text-align: right;
}

dd {
    margin: 0 0 5px 0;
    text-align: left;
}

blockquote {
    margin: 1.5em 0;
    padding: 0 1.6em 0 1.6em;
    border-left: var(--whitegrey) 0.5em solid;
}

blockquote p {
    margin: 0.8em 0;
    font-size: 1.2em;
    font-weight: 300;
}

blockquote small {
    display: inline-block;
    margin: 0.8em 0 0.8em 1.5em;
    font-size: 0.9em;
    opacity: 0.8;
}

/* Quotation marks */
blockquote small:before {
    content: "\2014 \00A0";
}

blockquote cite {
    font-weight: bold;
}

blockquote cite a {
    font-weight: normal;
}

a {
    color: color-mod(var(--blue) l(-5%));
    text-decoration: none;
}

a:hover {
    text-decoration: underline;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    margin-top: 0;
    line-height: 1.15;
    font-weight: 600;
    text-rendering: optimizeLegibility;
}

h1 {
    margin: 0 0 0.5em 0;
    font-size: 5.5rem;
    font-weight: 600;
}

@media (max-width: 500px) {
    h1 {
        font-size: 2.2rem;
    }
}

h2 {
    margin: 1.5em 0 0.5em 0;
    font-size: 2.2rem;
}

@media (max-width: 500px) {
    h2 {
        font-size: 1.8rem;
    }
}

h3 {
    margin: 1.5em 0 0.5em 0;
    font-size: 1.8rem;
    font-weight: 500;
}

@media (max-width: 500px) {
    h3 {
        font-size: 1.7rem;
    }
}

h4 {
    margin: 1.5em 0 0.5em 0;
    font-size: 1.6rem;
    font-weight: 500;
}

h5 {
    margin: 1.5em 0 0.5em 0;
    font-size: 1.4rem;
    font-weight: 500;
}

h6 {
    margin: 1.5em 0 0.5em 0;
    font-size: 1.4rem;
    font-weight: 500;
}

.z-100 {
    z-index: 100;
}

.slick-slide img {
    display: block;
    margin-left: auto;
    margin-right: auto;
    max-height: fit-content !important;
}

.slick-slide.slick-center img {
    transform: scale(0.9);
}

.slick-dots li button::before {
    font-size: 12px !important;
    color: white !important;
}

.slick-dots {
    position: revert !important;
    bottom: -35px !important;
}

.nav__submenu {
    display: none;
}

.nav__menu-item:hover .nav__submenu {
    display: block;
}

html {
    box-sizing: border-box;
}

*,
*:before,
*:after {
    box-sizing: border-box;
}

body {
    margin: 0;
    font-family: "Open Sans", sans-serif;
    font-size: 16px;
    line-height: 1.5;
}

.menu-container {
    text-align: center;
}

nav ul {
    list-style: none;
    padding-left: 0;
    margin-top: 0;
    margin-bottom: 0;
}

.nav a {
    display: flex;
    /*padding: 0 16px;*/
    line-height: inherit;
    cursor: pointer;
}

.nav__menu {
    line-height: 45px;
    font-weight: 700;
}

.nav__menu a:hover {
    text-decoration: none;
}

.nav__menu-item {
    display: inline-block;
    position: relative;
}

.nav__menu-item a:hover {
    /*background-color: #00aeef;*/
    text-decoration: none;
    color: #000000;
}

.nav__menu-item:hover .nav__submenu {
    display: block;
}

.nav__submenu {
    font-weight: 300;
    text-transform: none;
    display: none;
    position: absolute;
    background: transparent;
}

.navbar__submenu_wrapper {
    background: #fff;
    margin-top: 20px;
    box-shadow: 0px 9px 20px 0px rgba(0, 0, 0, 0.15);
    border-radius: 13px;
    width: fit-content;
}

.nav__submenu_block_wrapper_with_border {
    padding: 2rem;
    border-right: 1px solid rgba(234, 234, 234, 1);
}

.nav__submenu .nav__submenu-item a {
    color: #7e8695;
}

.nav__submenu-products {
    min-width: 550px;
}

.nav__submenu-resources {
    min-width: 220px;
}

.nav__submenu_block_wrapper {
    padding: 2rem;
}

.nav__submenu_block_wrapper-resources {
    padding: 1.5rem 2rem;
    padding-bottom: 0.7rem;
}

.nav__submenu-title {
    font-family: Google Sans;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0.03em;
    color: #000000;
}

.nav__submenu-description {
    font-family: Google Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0.03em;
    margin-left: 0.4rem;
    color: #000;
    display: flex;
    align-items: center;
}

.nav__submenu-item:hover {
    /*background: rgba(0, 0, 0, 0.1);*/
}

.link-underline a {
    text-decoration: underline !important;
}

.nav__submenu-title-wrapper .nav__submenu-title {
    margin-left: 1rem;
}

.nav__men-item-dropdown {
    /*display: flex;*/
}

.nav__submenu-title-wrapper:hover .arrow-icon {
    display: block;
}

.nav__submenu .arrow-icon {
    margin-left: 0.3rem;
    display: none;
}

.nav__submenu .blue-arrow {
    color: #3364fa;
}

.nav__submenu a {
    text-decoration: none !important;
}

.nav__submenu-title .nav__submenu-footer-title {
    font-family: Google Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 30px;
    letter-spacing: 0.03em;
    text-align: left;
    color: #000;
}

.link-underline .nav__submenu-item:hover {
    text-decoration: underline !important;
}

.mobile-footer-bg {
    background: #ffffff;
}

.mobile-footer {
    background: #ffffff;
    color: #fff;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 60px;
    /* top:134px; */
    z-index: 1000;
    /* height: 100vh; */
    overflow: scroll;
}

.mobile-navbar {
    background: #ffffff;
    border-top: 1px solid rgb(243 244 246);
    /* animation: mob-nav 200ms forwards; */
    animation-timing-function: ease-in-out;
    position: fixed;
}

@keyframes mob-nav {
    0% {
        height: 0vh;
    }

    100% {
        height: 100vh;
    }
}

.mobile-navbar .tab {
}

.mobile-navbar-content {
    background: #fff;
    color: #000;
    padding-top: 1rem;
    padding-bottom: 0px;
}

.mobile-navbar-sub-header {
    list-style: none;
    padding-left: 0;
    margin-top: 0;
    margin-bottom: 0;
}

.mobile-navbar-sub-header .nav__submenu-item {
    font-family: Google Sans;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 36px;
    letter-spacing: 0.02em;
    text-align: left;
}

.mobile-navbar-sub-header .nav__submenu-item a {
    color: #000;
    text-decoration: none !important;
}

.mobile-navbar-content .nav__submenu_block_wrapper_with_border,
.mobile-navbar-content .nav__submenu_block_wrapper {
    padding: 1rem;
}

.navbar-login-button {
    border-radius: 25px;
    border: 1px solid #c8cedb;
    font-family: Google Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.02em;
    text-align: center;
    color: #7e8695;
    transition: 0.3s all;
    background: transparent;
}

.mobile-navbar-button-block {
    display: flex;
    margin: 0.3rem 0;
    /* margin-left: 0.8rem; */
}

.mobile-navbar-button-block .mob-login {
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    color: #4a5568;
    padding: 14px 60px;
    background: #edf2f7;
    border-radius: 7px;
}

.mobile-navbar-button-block .mob-signup {
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    color: #ffffff;
    padding: 10px 24px;
    background: #0083ff;
    border-radius: 7px;
    margin-left: 20px;
    line-height: 28px;
}

.mobile-navbar-login-button {
    background: #edf2f7;
    color: #4a5568;
    border-color: #fff;
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 28px;
    margin-right: 0.3rem;
}

.navbar-login-button:hover {
    /*background: #7E8695;*/
    color: #ffffff;
}

.wa-shorturl-preview {
    color: #7e8695;
    transition: 0.3s all;
    background: transparent;
    text-align: center;
    font-weight: 600;
    border-radius: 9999px;
    width: 100%;
    padding: 1rem;
    border: "2px solid #C8CEDB";
}

.wa-shorturl-preview:hover {
    color: #ffffff;
    text-decoration: none;
}

footer .sub-title {
    font-family: Google Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 30px;
    letter-spacing: 0.03em;
    text-align: left;
    color: #000000;
}

.nav__submenu-title-wrapper {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
    text-decoration: none !important;
    width: 185px;
}

a.nav__submenu-title-wrapper {
    text-decoration: none !important;
}

.footer-line {
    position: absolute;
    top: 9px;
    background: #e6e6e6;
    height: 1px;
    width: 100%;
    margin: 0;
}

.footer_logo {
    height: 66px;
    object-fit: contain;
}

.whatsapp-page-nav-btn {
    width: 138px;
    height: 42px;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    letter-spacing: 0.02em;
    background-color: #7bb672;
    border-radius: 25px;
    color: white;
    font-family: "Google Sans";
    font-style: normal;
}

.whatsapp-page-nav-items {
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.02em;
    color: #666666;
    margin-inline: 1em;
    font-family: "Google Sans";
    font-style: normal;
}

.whatsapp-mobile-nav a {
    color: white;
    text-decoration: none;
}

.class-copy {
    position: absolute;
    right: 0px;
    top: 5px;
    cursor: pointer;
    margin: 0;
}

.class-copy > img {
    margin: 0 !important;
    width: 1.5rem !important;
}

.class-copy > p {
    display: none;
    color: rgb(0, 120, 208) !important;
    position: absolute;
    right: -20px;
}

.post-full-content iframe[src*="youtube"] {
    width: 45vw !important;
    max-width: 720px !important;
    height: 320px !important;
}

.edu-indus iframe[src*="youtube"] {
    width: 45vw !important;
    max-width: 720px !important;
    height: 376px !important;
}

.ecom-indus iframe[src*="youtube"] {
    width: 35vw !important;
    max-width: 720px !important;
    height: 305px !important;
}
* {
    scrollbar-width: thin;
    scrollbar-color: #00000033 #ffffff1a;
}
/* /Works on Chrome/Edge/Safari */
*::-webkit-scrollbar {
    width: 6px;
    height: 6px;
}
*::-webkit-scrollbar-track {
    background: #ffffff1a;
}
*::-webkit-scrollbar-thumb {
    background-color: #00000033;
}
@media (max-width: 1020px) {
    .post-full-content iframe[src*="youtube"] {
        width: 55vw !important;
        height: 265px !important;
    }

    .edu-indus iframe[src*="youtube"] {
        width: 55vw !important;
        height: 265px !important;
    }
}

@media (max-width: 790px) {
    .post-full-content iframe[src*="youtube"] {
        width: 80vw !important;
    }

    .edu-indus iframe[src*="youtube"] {
        width: 80vw !important;
    }
}

@media (max-width: 480px) {
    .post-full-content iframe[src*="youtube"] {
        width: 90vw !important;
        height: 210px !important;
    }

    .edu-indus iframe[src*="youtube"] {
        width: 90vw !important;
        height: 200px !important;
    }

    .ecom-indus iframe[src*="youtube"] {
        width: 87vw !important;
        height: 200px !important;
    }
}

* {
    scrollbar-width: thin;
    scrollbar-color: #00000033 #ffffff1a;
}
/* /Works on Chrome/Edge/Safari */
*::-webkit-scrollbar {
    width: 6px;
    height: 6px;
}
*::-webkit-scrollbar-track {
    background: #ffffff1a;
}
*::-webkit-scrollbar-thumb {
    background-color: #00000033;
}
