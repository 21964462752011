@tailwind base;

/* Write your own custom base styles here */

/* Start purging... */
@tailwind components;
/* Stop purging. */

/* Write your own custom component styles here */
body {
    width: 100vw;
    overflow-x: hidden;
}

[class*="Snackbar_snackbar__GsYZl"] {
    z-index: 50;
}

/* Start purging... */
@tailwind utilities;
/* Stop purging. */

/* Your own custom utilities */

.slick-slide.slick-center img {
    transform: scale(1.1);
}

@keyframes shimmer {
    0% {
        background-position: -200px 0;
    }
    100% {
        background-position: 200px 0;
    }
}

.animate-shimmer {
    animation: shimmer 1.5s infinite linear;
}

.modal-image-section {
    width: 680px;
    height: 680px;
}
@layer components {
    .btn-primary-blue {
        @apply bg-blue-600 text-lg w-40 text-white px-6 py-3 rounded-lg hover:bg-blue-700 hover:shadow-lg;
    }
    .btn-primary-white {
        @apply bg-white text-lg w-40 text-black px-6 py-3 rounded-lg hover:bg-gray-50 hover:shadow-md;
    }
}
