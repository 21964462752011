.react-calendar {
    border: none !important;
    width: 364px !important;
}

.react-calendar__navigation__prev2-button,
.react-calendar__navigation__next2-button {
    visibility: hidden;
}

.react-calendar__navigation__prev-button,
.react-calendar__navigation__next-button {
    background: white !important;
    font-size: 28px !important;
    color: #6b7280 !important;
}

.react-calendar__navigation__prev-button:hover,
.react-calendar__navigation__next-button:hover {
    color: #1f2937 !important;
}

.react-calendar__navigation__label {
    font-weight: 400 !important;
    font-size: 16px !important;
    background-color: white !important;
}

.react-calendar__navigation__label:hover {
    background: none !important;
}

.react-calendar__month-view__weekdays__weekday {
    font-size: 12px !important;
    color: #4b5563 !important;
    font-weight: 400 !important;
    max-width: 44px !important;
    padding: 2px !important;
    margin: 4px !important;
}

.react-calendar__month-view__weekdays__weekday abbr {
    text-decoration: none !important;
}

.react-calendar__tile {
    border-radius: 100% !important;
}

.react-calendar__tile:disabled {
    background: white !important;
}

.react-calendar__month-view__days__day {
    background: rgba(239, 246, 255, 0.7) !important;
    color: #3b82f6 !important;
    font-weight: 700 !important;
    max-width: 44px !important;
    height: 40px !important;
    padding: 2px !important;
    margin: 4px !important;
}

.react-calendar__month-view__days__day:disabled {
    color: #6b7280 !important;
}

.react-calendar__month-view__days__day:disabled:hover {
    background: none !important;
}

.react-calendar__month-view__days__day:hover {
    background: rgba(191, 219, 254, 0.7) !important;
}

.react-calendar__month-view__days__day--neighboringMonth {
    visibility: hidden;
}

.react-calendar__month-view__days__day--neighboringMonth:disabled {
    color: #d1d5db !important;
}

.react-calendar__tile--active {
    color: white !important;
    background: #1d4ed8 !important;
}

.react-calendar__tile--active:hover {
    color: white !important;
    background: #1d4ed8 !important;
}

.react-calendar__tile--now {
    position: relative;
}

.react-calendar__tile--now::after {
    content: " " !important;
    width: 5px !important;
    height: 5px !important;
    color: white;
    position: absolute !important;
    left: 44% !important;
    bottom: 4px !important;
    background-color: #1d4ed8 !important;
    border-radius: 100% !important;
}

.react-calendar__tile--active::after {
    content: " " !important;
    width: 5px !important;
    height: 5px !important;
    color: #1d4ed8;
    position: absolute !important;
    left: 44% !important;
    bottom: 4px !important;
    background-color: white !important;
    border-radius: 100% !important;
}

/* this command for applying styles to demo page calender */

@media only screen and (min-width: 768px) {
    .demo .react-calendar {
        width: 450px !important;
    }
    .demo .react-calendar__navigation {
        padding-right: 40px !important;
    }

    .demo .react-calendar__navigation__label {
        font-weight: 400 !important;
        font-size: 16px !important;
        background-color: white !important;
    }
    .demo .react-calendar__month-view__weekdays__weekday {
        max-width: 40px !important;
        padding-right: 5px !important;
        padding-left: 5px !important;
        margin: 10px !important;
        align-items: center;
        font-size: 15px !important;
    }

    .demo .react-calendar__month-view__days__day {
        max-width: 40px !important;
        height: 40px !important;
        margin: 10px !important;
        font-size: 14px !important;
    }

    .demo .react-calendar__tile--now::after {
        bottom: 7px !important;
    }

    .demo .react-calendar__tile--active::after {
        bottom: 7px !important;
    }
}
