/* Table of Contents
/* ------------------------------------------------------------

This is a development CSS file which is built to a minified
production stylesheet in assets/built/screen.css

1.  Global Styles
2.  Layout
3.  Special Templates
4.  Site Header
  4.1 Home header
  4.2 Archive header
5.  Site Navigation
6.  Post Feed
7.  Single Post
  7.1. Post Byline
  7.2. Members Subscribe Form
  7.3. Comments
  7.4. Related Posts
  7.5. Koenig Styles
8.  Author Template
9.  Error Template
10. Subscribe Overlay
11. Site Footer
12. Dark Mode
13. Whatsapp ChatBot Landing
14. Integrations
15. Integrations CMS
16. Integrations - E Commerce
 */

/* 1. Global - Set up the things
/* ---------------------------------------------------------- */
@import "global.css";

html.cas {
    font-size: 100%;
}

html.cas body {
    background: #fff;
}

html.cas .img {
    display: block;
    width: 100%;
    height: 100%;
    background-position: center center;
    background-size: cover;
    border-radius: 100%;
}

html.cas .hidden {
    visibility: hidden;
    position: absolute;
    text-indent: -9999px;
}

/* 2. Layout - Page building blocks
/* ---------------------------------------------------------- */

html.cas .site-wrapper {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

html.cas .site-main {
    z-index: 100;
    flex-grow: 1;
}

/* Full width page blocks */
html.cas .outer {
    position: relative;
    padding: 0 5vw;
}

/* Centered content container blocks */
html.cas .inner {
    margin: 0 auto;
    max-width: 1040px;
    width: 100%;
}

/* Usage:

<div class='outer'>
  <div class='inner'>
    Centered content
  </div>
</div>

*/

/* 3. Special Template Styles
/* ---------------------------------------------------------- */

@media (min-width: 900px) {
    html.cas .home-template .post-feed,
    html.cas .tag-template .post-feed,
    html.cas .author-template .post-feed {
        padding: 40px 0 5vw;
        border-top-left-radius: 3px;
        border-top-right-radius: 3px;
    }

    html.cas .home-template .site-nav {
        position: relative;
    }
}

/* 4. Site Header
/* ---------------------------------------------------------- */

html.cas .site-header-background {
    position: relative;
    margin-top: 64px;
    padding-bottom: 12px;
    color: #fff;
    background: color-mod(var(--darkgrey) l(-5%)) no-repeat center center;
    background-size: cover;
}

html.cas .site-header-background:before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 10;
    display: block;
    background: rgba(0, 0, 0, 0.18);
}

html.cas .site-header-background:after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: auto;
    left: 0;
    z-index: 10;
    display: block;
    height: 140px;
    background: linear-gradient(rgba(0, 0, 0, 0.15), rgba(0, 0, 0, 0));
}

html.cas .site-header-background.no-image:before,
html.cas .site-header-background.no-image:after {
    display: none;
}

html.cas .site-header-content {
    z-index: 100;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 6vw 3vw;
    min-height: 200px;
    max-height: 340px;
}

html.cas .site-title {
    z-index: 10;
    margin: 0 0 0 -2px;
    padding: 0;
    font-size: 3.125rem;
    line-height: 1em;
    font-weight: 600;
}

html.cas .site-logo {
    max-height: 55px;
}

html.cas .site-description {
    z-index: 10;
    margin: 0;
    padding: 5px 0;
    font-size: 1.3125rem;
    line-height: 1.4em;
    font-weight: 400;
    opacity: 0.8;
}

/* 4.1 Home header
/* ---------------------------------------------------------- */

html.cas .site-home-header {
    z-index: 1000;
}

html.cas .site-home-header .site-header-background {
    margin-top: 0;
}

html.cas .site-home-header .site-header-content {
    padding: 5vw 3vw 6vw;
}

html.cas .site-home-header .site-title {
    font-size: 3.4375rem;
    text-align: center;
}

html.cas .site-home-header .site-description {
    font-size: 1.375rem;
    font-weight: 300;
    text-align: center;
}

/* 4.2 Archive header (tag and author post lists)
/* ---------------------------------------------------------- */

html.cas .site-archive-header .site-header-content {
    position: relative;
    align-items: stretch;
    padding: 12vw 0 20px;
    min-height: 200px;
    max-height: 600px;
}

html.cas .site-archive-header .no-image {
    padding-top: 0;
    padding-bottom: 0;
    color: var(--darkgrey);
    background: #fff;
    opacity: 1;
}

html.cas .site-archive-header .no-image .site-description {
    color: var(--midgrey);
    opacity: 1;
}

html.cas .site-archive-header .no-image .site-header-content {
    padding: 5vw 0 10px;
    border-bottom: 1px solid color-mod(var(--lightgrey) l(+12%));
}

/* Special header styles for smaller screens */

@media (max-width: 900px) {
    html.cas .site-header-content {
        padding-bottom: 9vw;
    }
}

@media (max-width: 500px) {
    html.cas .site-home-header .site-title {
        font-size: 2.625rem;
    }

    html.cas .site-home-header .site-description {
        font-size: 1.125rem;
    }

    html.cas .site-archive-header .site-header-content {
        flex-direction: column;
        align-items: center;
        min-height: unset;
    }

    html.cas .site-archive-header .site-title {
        font-size: 2.625rem;
        text-align: center;
    }

    html.cas .site-archive-header .no-image .site-header-content {
        padding: 12vw 0 20px;
    }
}

/* 5. Site Navigation
/* ---------------------------------------------------------- */

html.cas .site-nav-main {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1000;
    background: color-mod(var(--darkgrey) l(-5%));
}

html.cas .site-nav {
    position: relative;
    z-index: 100;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    overflow: hidden;
    height: 64px;
    font-size: 0.8125rem;
}

html.cas .site-nav-left-wrapper {
    position: relative;
    flex: 1 0 auto;
    display: flex;
}

html.cas .site-header-background:not(.responsive-header-img) .site-nav-left-wrapper:after,
html.cas .site-nav-main .site-nav-left-wrapper:after {
    content: "";
    position: absolute;
    top: 0;
    z-index: 1000;
    width: 40px;
    height: 100%;
}

html.cas .site-header-background:not(.responsive-header-img) .site-nav-left-wrapper:after,
html.cas .site-nav-main .site-nav-left-wrapper:after {
    right: 0;
    background: linear-gradient(to right, color-mod(var(--darkgrey) l(-5%) a(0)) 0%, color-mod(var(--darkgrey) l(-5%)) 100%);
}

html.cas .site-nav-left {
    flex: 1 0 auto;
    display: flex;
    align-items: center;
    overflow-x: auto;
    overflow-y: hidden;
    -webkit-overflow-scrolling: touch;
    margin-right: 10px;
    padding: 10px 0 80px;
    font-weight: 500;
    letter-spacing: 0.2px;
    text-transform: uppercase;
    white-space: nowrap;

    -ms-overflow-scrolling: touch;
}

html.cas .site-nav-left .nav li:last-of-type {
    padding-right: 20px;
}

/* Site Nav Hack Explanation (above):

What's happening above is .site-nav-left is set to overflow-x and allow sideways scrolling, so that when there isn't enough space for all nav items (either due to lots of nav items, or a small viewport), you can still scroll side-to-side to reach them. Also, there is a small gradient on the left and right side covering the menu so that the menu goes offscreen smoothly.

The knock-on effect of this is ugly browser-scroll bars at the bottom, so 80px of padding-bottom and a 40px fixed height parent (.site-nav) hides that entirely. Slightly hacky code. But nice clean end-result.

*/

html.cas .site-nav-logo {
    position: relative;
    z-index: 100;
    flex-shrink: 0;
    display: inline-block;
    margin-right: 32px;
    padding: 12px 0;
    color: #fff;
    font-size: 1.0625rem;
    line-height: 1.8rem;
    font-weight: bold;
    letter-spacing: -0.5px;
    text-transform: none;
}

html.cas .site-nav-logo:hover {
    text-decoration: none;
}

html.cas .site-nav-logo img {
    display: block;
    width: auto;
    height: 21px;
}

html.cas .site-home-header .site-nav-logo {
    display: none;
}

html.cas .site-nav-content {
    position: relative;
    align-self: flex-start;
}

html.cas .nav {
    position: absolute;
    z-index: 1000;
    display: flex;
    margin: 0 0 0 -12px;
    padding: 0;
    list-style: none;
    transition: all 1s cubic-bezier(0.19, 1, 0.22, 1);
}

html.cas .nav li {
    display: block;
    margin: 0;
    padding: 0;
}

html.cas .nav li a {
    position: relative;
    display: block;
    padding: 12px 12px;
    color: #fff;
    opacity: 0.8;
    transition: opacity 0.35s ease-in-out;
}

html.cas .nav li a:hover {
    text-decoration: none;
    opacity: 1;
}

html.cas .nav li a:before {
    content: "";
    position: absolute;
    right: 100%;
    bottom: 8px;
    left: 12px;
    height: 1px;
    background: #fff;
    opacity: 0.25;
    transition: all 0.35s ease-in-out;
}

html.cas .nav li a:hover:before {
    right: 12px;
    opacity: 0.5;
}

html.cas .nav-post-title-active .nav {
    visibility: hidden;
    opacity: 0;
    transform: translateY(-175%);
}

html.cas .nav-post-title {
    visibility: hidden;
    position: absolute;
    top: 2px;
    color: #fff;
    font-size: 1.0625rem;
    font-weight: 400;
    text-transform: none;
    opacity: 0;
    transition: all 1s cubic-bezier(0.19, 1, 0.22, 1);
    transform: translateY(175%);
}

html.cas .nav-post-title.dash {
    left: -25px;
}

html.cas .nav-post-title.dash:before {
    content: "– ";
    opacity: 0.5;
}

html.cas .nav-post-title-active .nav-post-title {
    visibility: visible;
    opacity: 1;
    transform: translateY(0);
}

html.cas .site-nav-right {
    flex: 0 1 auto;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 10px 0;
    height: 64px;
}

html.cas .site-nav-right .nav {
    position: relative;
    margin: 0;
}

html.cas .site-nav-right .nav a {
    white-space: nowrap;
}

html.cas .site-nav-right .nav a:before {
    display: none;
}

html.cas .site-nav-right .nav li:last-of-type a {
    margin-right: -12px;
}

html.cas .social-links {
    flex-shrink: 0;
    display: flex;
    align-items: center;
}

html.cas .social-link {
    display: inline-block;
    margin: 0;
    padding: 10px;
    opacity: 0.8;
}

html.cas .social-link:hover {
    opacity: 1;
}

html.cas .social-link svg {
    height: 1.125rem;
    fill: #fff;
}

html.cas .social-link-fb svg {
    height: 1rem;
}

html.cas .social-link-wb svg {
    height: 1rem;
}

html.cas .social-link-wb svg path {
    stroke: #fff;
}

html.cas .social-link-rss svg {
    height: 1.1875rem;
}

html.cas .subscribe-button {
    display: block;
    margin: 0 0 0 10px;
    padding: 4px 10px;
    border: #fff 1px solid;
    color: #fff;
    line-height: 1em;
    border-radius: 10px;
    opacity: 0.8;
}

html.cas .subscribe-button:hover {
    text-decoration: none;
    opacity: 1;
}

html.cas .site-nav-right .nav + .subscribe-button {
    margin-left: 24px;
}

html.cas .rss-button {
    padding: 10px 8px;
    opacity: 0.8;
}

html.cas .rss-button:hover {
    opacity: 1;
}

html.cas .rss-button svg {
    margin-bottom: 1px;
    height: 1.3125rem;
    fill: #fff;
}

/* Special behaviors for home navigation */

html.cas .home-template .site-nav-main {
    z-index: 100;
}

html.cas .home-template .site-nav-main .site-nav {
    opacity: 0;
    transition: all 0.5s cubic-bezier(0.19, 1, 0.22, 1) 0s;
}

html.cas .home-template .site-nav-main .fixed-nav-active {
    opacity: 1;
    transition: all 0.5s cubic-bezier(0.19, 1, 0.22, 1) 0.05s;
}

@media (max-width: 700px) {
    html.cas .site-home-header .site-nav {
        margin-left: -5vw;
    }

    html.cas .site-nav-main {
        padding-right: 0;
        padding-left: 0;
    }

    html.cas .site-nav-left {
        margin-right: 0;
        padding-left: 5vw;
    }

    html.cas .site-nav-right {
        display: none;
    }
}

/* 6. Post Feed
/* ---------------------------------------------------------- */

html.cas .posts {
    overflow-x: hidden;
}

html.cas .post-feed {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    margin: 0 -20px;
    padding: 50px 0 0;
    background: #fff;
}

html.cas .post-card {
    position: relative;
    flex: 1 1 301px;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    margin: 0 0 40px;
    padding: 0 20px 40px;
    min-height: 220px;
    border-bottom: 1px solid color-mod(var(--lightgrey) l(+12%));
    background-size: cover;
}

html.cas .post-card-image-link {
    position: relative;
    display: block;
    overflow: hidden;
    border-radius: 3px;
}

html.cas .post-card-image {
    width: 100%;
    height: 200px;
    background: #fff no-repeat center center;

    object-fit: contain;
}

html.cas .post-card-content-link {
    position: relative;
    display: block;
    color: var(--darkgrey);
}

html.cas .post-card-content-link:hover {
    text-decoration: none;
}

html.cas .post-card-header {
    margin: 15px 0 0;
}

html.cas .post-feed .no-image .post-card-content-link {
    padding: 0;
}

html.cas .no-image .post-card-header {
    margin-top: 0;
}

html.cas .post-card-primary-tag {
    margin: 0 0 0.2em;
    color: var(--blue);
    font-size: 0.75rem;
    font-weight: 500;
    letter-spacing: 0.2px;
    text-transform: uppercase;
    line-height: 1.6em;
}

html.cas .post-card-title {
    margin: 0 0 0.4em;
    line-height: 1.15em;
    transition: color 0.2s ease-in-out;
    font-size: 1.375rem;
}

html.cas .no-image .post-card-title {
    margin-top: 0;
}

html.cas .post-card-content {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
}

html.cas .post-card-excerpt {
    max-width: 56em;
    color: color-mod(var(--midgrey) l(-8%));
    font-family: Georgia, serif;
}

html.cas .post-card-excerpt p {
    font-size: 1rem;
    line-height: 1.6em;
    margin-bottom: 1em;
}

html.cas .post-card-meta {
    display: flex;
    align-items: flex-start;
    padding: 0;
}

html.cas .author-profile-image,
html.cas .avatar-wrapper {
    display: block;
    width: 100%;
    height: 100%;
    background: color-mod(var(--lightgrey) l(+10%));
    border-radius: 100%;

    object-fit: cover;
}

html.cas .post-card-meta .profile-image-wrapper,
html.cas .post-card-meta .avatar-wrapper {
    position: relative;
}

html.cas .author-list {
    display: flex;
    flex-wrap: wrap;
    margin: 0 0 0 4px;
    padding: 0;
    list-style: none;
}

html.cas .author-list-item {
    position: relative;
    flex-shrink: 0;
    margin: 0;
    padding: 0;
}

html.cas .static-avatar {
    display: block;
    overflow: hidden;
    margin: 0 0 0 -6px;
    width: 34px;
    height: 34px;
    border: #fff 2px solid;
    border-radius: 100%;
}

html.cas .author-name-tooltip {
    position: absolute;
    bottom: 105%;
    z-index: 999;
    display: block;
    padding: 2px 8px;
    color: white;
    font-size: 0.75rem;
    letter-spacing: 0.2px;
    white-space: nowrap;
    background: var(--darkgrey);
    border-radius: 3px;
    box-shadow: rgba(39, 44, 49, 0.08) 0 12px 26px, rgba(39, 44, 49, 0.03) 1px 3px 8px;
    opacity: 0;
    transition: all 0.35s cubic-bezier(0.4, 0.01, 0.165, 0.99);
    transform: translateY(6px);
    pointer-events: none;
}

html.cas .author-list-item:hover .author-name-tooltip {
    opacity: 1;
    transform: translateY(0px);
}

@media (max-width: 700px) {
    html.cas .author-name-tooltip {
        display: none;
    }
}

html.cas .post-card-byline-content {
    flex: 1 1 50%;
    display: flex;
    flex-direction: column;
    margin: 2px 0 0 6px;
    color: color-mod(var(--midgrey) l(-7%));
    font-size: 0.75rem;
    line-height: 1.4em;
    font-weight: 400;
    letter-spacing: 0.2px;
    text-transform: uppercase;
}

html.cas .post-card-byline-content span {
    margin: 0;
}

html.cas .post-card-byline-content a {
    color: color-mod(var(--darkgrey) l(+20%));
    font-weight: 600;
}

html.cas .post-card-byline-date {
    font-size: 0.75rem;
}

html.cas .post-card-byline-date .bull {
    display: inline-block;
    margin: 0 4px;
    opacity: 0.6;
}

html.cas .single-author-byline {
    display: flex;
    flex-direction: column;
    margin-left: 5px;
    color: color-mod(var(--midgrey) l(-10%));
    font-size: 0.8125rem;
    line-height: 1.4em;
    font-weight: 500;
}

html.cas .single-author {
    display: flex;
    align-items: center;
}

html.cas .single-author .static-avatar {
    margin-left: -2px;
}

html.cas .single-author-name {
    display: inline-block;
}

/* Special Styling for home page grid (below):

The first post in the list is styled to be bigger than the others and take over
the full width of the grid to give it more emphasis. Wrapped in a media query to
make sure this only happens on large viewports / desktop-ish devices.

 */

@media (min-width: 795px) {
    html.cas .post-card-large {
        flex: 1 1 100%;
        flex-direction: row;
        padding-bottom: 40px;
        min-height: 280px;
        border-top: 0;
    }

    html.cas .post-card-large:hover {
        border-bottom-color: color-mod(var(--lightgrey) l(+10%));
    }

    html.cas .post-card-large:not(.no-image) .post-card-header {
        margin-top: 0;
    }

    html.cas .post-card-large .post-card-image-link {
        position: relative;
        flex: 1 1 auto;
        margin-bottom: 0;
        min-height: 380px;
    }

    html.cas .post-card-large .post-card-image {
        position: absolute;
        width: 100%;
        height: 100%;
    }

    html.cas .post-card-large .post-card-content {
        flex: 0 1 361px;
        justify-content: center;
    }

    html.cas .post-card-large .post-card-title {
        margin-top: 0;
        font-size: 2rem;
    }

    html.cas .post-card-large .post-card-content-link {
        padding: 0 0 0 40px;
    }

    html.cas .post-card-large .post-card-meta {
        padding: 0 0 0 40px;
    }

    html.cas .post-card-large .post-card-excerpt p {
        margin-bottom: 1.5em;
        font-size: 1.125rem;
        line-height: 1.5em;
    }
}

/* Adjust some margins for smaller screens */
@media (max-width: 1170px) {
    html.cas .post-card {
        margin-bottom: 5vw;
    }
}

@media (max-width: 650px) {
    html.cas .post-feed {
        padding-top: 5vw;
    }

    html.cas .post-card {
        margin-bottom: 5vw;
    }
}

@media (max-width: 500px) {
    html.cas .post-card-title {
        font-size: 1.1875rem;
    }

    html.cas .post-card-excerpt {
        font-size: 1rem;
    }
}

/* 7. Single Post
/* ---------------------------------------------------------- */

html.cas .post-template .site-main,
html.cas .page-template .site-main {
    margin-top: 64px;
    padding-bottom: 4vw;
    background: #fff;
}

html.cas .post-full-header {
    position: relative;
    margin: 0 auto;
    padding: 70px 170px 50px;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
}

html.cas .post-full-tags {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    color: var(--midgrey);
    font-size: 0.8125rem;
    line-height: 1.4em;
    font-weight: 600;
    text-transform: uppercase;
}

html.cas .post-full-meta-date {
    color: var(--midgrey);
    font-size: 0.75rem;
    font-weight: 400;
}

@media (max-width: 1170px) {
    html.cas .post-full-header {
        padding: 60px 11vw 50px;
    }
}

@media (max-width: 800px) {
    html.cas .post-full-header {
        padding-right: 5vw;
        padding-left: 5vw;
    }
}

@media (max-width: 500px) {
    html.cas .post-full-header {
        padding: 20px 0 35px;
    }
}

html.cas .post-full-title {
    margin: 0 0 0.2em;
    font-size: 3.4375rem;
    color: color-mod(var(--darkgrey) l(-5%));
}

html.cas .post-full-custom-excerpt {
    margin: 20px 0 0;
    color: var(--midgrey);
    font-family: Georgia, serif;
    font-size: 1.4375rem;
    line-height: 1.4em;
    font-weight: 300;
}

html.cas .date-divider {
    display: inline-block;
    margin: 0 6px 1px;
    font-weight: 300;
}

html.cas .post-full-image {
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
    margin: 25px 0 50px;
    background: color-mod(var(--lightgrey) l(+10%));
    border-radius: 3px;
}

html.cas .post-full-image img {
    max-width: 1040px;
    width: 100%;
    height: auto;
}

html.cas .post-full-content {
    position: relative;
    margin: 0 auto;
    padding: 0 170px 6vw;
    min-height: 230px;
    font-family: Georgia, serif;
    font-size: 1.25rem;
    line-height: 1.6em;
    background: #fff;
}

@media (max-width: 1170px) {
    html.cas .post-full-content {
        padding: 0 11vw;
    }
}

@media (max-width: 800px) {
    html.cas .post-full-content {
        padding: 0 5vw;
        font-size: 1.125rem;
    }
}

@media (max-width: 500px) {
    html.cas .post-full-custom-excerpt {
        font-size: 1.1875rem;
        line-height: 1.5em;
    }
}

html.cas .no-image .post-full-content {
    padding-top: 0;
}

html.cas .no-image .post-full-content:before,
html.cas .no-image .post-full-content:after {
    display: none;
}

html.cas .post-full-content h1,
html.cas .post-full-content h2,
html.cas .post-full-content h3,
html.cas .post-full-content h4,
html.cas .post-full-content h5,
html.cas .post-full-content h6,
html.cas .post-full-content p,
html.cas .post-full-content ul,
html.cas .post-full-content ol,
html.cas .post-full-content dl,
html.cas .post-full-content pre,
html.cas .post-full-content blockquote,
html.cas .post-full-comments,
html.cas .post-full-content .heading,
html.cas .footnotes {
    margin: 0 0 1.5em 0;
    min-width: 100%;
}

@media (max-width: 500px) {
    html.cas .post-full-content p,
    html.cas .post-full-content ul,
    html.cas .post-full-content ol,
    html.cas .post-full-content dl,
    html.cas .post-full-content pre,
    html.cas .post-full-comments,
    html.cas .footnotes {
        margin-bottom: 1.28em;
    }
}

html.cas .post-full-content li {
    word-break: break-word;
}

html.cas .post-full-content li p {
    margin: 0;
}

html.cas .post-full-content a {
    color: var(--darkgrey);
    word-break: break-word;
    box-shadow: var(--darkgrey) 0 -1px 0 inset;
    transition: all 0.2s ease-in-out;
}

html.cas .post-full-content a:hover {
    color: var(--blue);
    text-decoration: none;
    box-shadow: var(--blue) 0 -1px 0 inset;
}

html.cas .post-full-content strong,
html.cas .post-full-content em {
    color: color-mod(var(--darkgrey) l(-5%));
}

html.cas .post-full-content small {
    display: inline-block;
    line-height: 1.6em;
}

html.cas .post-full-content li:first-child {
    margin-top: 0;
}

html.cas .post-full-content img,
html.cas .post-full-content video {
    display: block;
    margin: 1.5em auto;
    max-width: 1040px;
    height: auto;
}

@media (max-width: 1040px) {
    html.cas .post-full-content img,
    html.cas .post-full-content video {
        width: 100%;
    }
}

/* Full bleed images (#full)
Super neat trick courtesy of @JoelDrapper

Usage (In Ghost edtior):

![img](/some/image.jpg#full)

*/
html.cas .post-full-content img[src$="#full"] {
    max-width: none;
    width: 100vw;
}

/* Image captions

Usage (In Ghost editor):

![img](/some/image.jpg)
<small>Your image caption</small>

*/
html.cas .post-full-content img + br + small {
    display: block;
    margin-top: -3em;
    margin-bottom: 1.5em;
    text-align: center;
}

/* Override third party iframe styles */
html.cas .post-full-content iframe {
    margin: 0 auto !important;
}

html.cas .post-full-content blockquote {
    margin: 0 0 1.5em;
    padding: 0 1.5em;
    border-left: color-mod(var(--blue)) 3px solid;
}

@media (max-width: 500px) {
    html.cas .post-full-content blockquote {
        padding: 0 1.3em;
    }
}

html.cas .post-full-content blockquote p {
    margin: 0 0 1em 0;
    color: inherit;
    font-size: inherit;
    line-height: inherit;
    font-style: italic;
}

html.cas .post-full-content blockquote p:last-child {
    margin-bottom: 0;
}

html.cas .post-full-content code {
    padding: 0 5px 2px;
    font-size: 0.8em;
    line-height: 1em;
    font-weight: 400 !important;
    background: var(--whitegrey);
    border-radius: 3px;
}

html.cas .post-full-content p code {
    word-break: break-all;
}

html.cas .post-full-content pre {
    overflow-x: auto;
    margin: 1.5em 0 3em;
    padding: 20px;
    max-width: 100%;
    border: color-mod(var(--darkgrey) l(-10%)) 1px solid;
    color: var(--whitegrey);
    font-size: 0.875rem;
    line-height: 1.5em;
    background: color-mod(var(--darkgrey) l(-3%));
    border-radius: 5px;
}

html.cas .post-full-content pre ::selection {
    color: color-mod(var(--midgrey) l(-25%));
}

html.cas .post-full-content pre code {
    padding: 0;
    font-size: inherit;
    line-height: inherit;
    background: transparent;
}

html.cas .post-full-content pre code :not(span) {
    color: inherit;
}

html.cas .post-full-content .fluid-width-video-wrapper {
    margin: 1.5em 0 3em;
}

html.cas .post-full-content hr {
    margin: 2em 0;
}

html.cas .post-full-content hr:after {
    content: "";
    position: absolute;
    top: -15px;
    left: 50%;
    display: block;
    margin-left: -10px;
    width: 1px;
    height: 30px;
    background: color-mod(var(--lightgrey) l(+10%));
    box-shadow: #fff 0 0 0 5px;
    transform: rotate(45deg);
}

html.cas .post-full-content hr + p {
    margin-top: 1.2em;
}

html.cas .post-full-content h1,
html.cas .post-full-content h2,
html.cas .post-full-content h3,
html.cas .post-full-content h4,
html.cas .post-full-content h5,
html.cas .post-full-content h6,
html.cas .post-full-content .heading {
    color: color-mod(var(--darkgrey) l(-5%));
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

html.cas .post-full-content h1 {
    margin: 0.5em 0 0.4em;
    font-size: 2.625rem;
    line-height: 1.25em;
    font-weight: 600;
}

html.cas .post-full-content p + h1 {
    margin-top: 0.8em;
}

@media (max-width: 800px) {
    html.cas .post-full-content h1 {
        font-size: 2rem;
        line-height: 1.25em;
    }
}

html.cas .post-full-content h2 {
    margin: 0.5em 0 0.4em;
    font-size: 2rem;
    line-height: 1.25em;
    font-weight: 600;
}

html.cas .post-full-content p + h2 {
    margin-top: 0.8em;
}

@media (max-width: 800px) {
    html.cas .post-full-content h2 {
        margin-bottom: 0.3em;
        font-size: 1.75rem;
        line-height: 1.25em;
    }
}

html.cas .post-full-content h3,
html.cas .post-full-content .heading {
    margin: 0.5em 0.2em;
    font-size: 1.5625rem;
    line-height: 1.3em;
    font-weight: 600;
}

html.cas .post-full-content h2 + h3 {
    margin-top: 0.7em;
}

@media (max-width: 800px) {
    html.cas .post-full-content h3,
    html.cas .post-full-content .heading {
        margin-bottom: 0.3em;
        font-size: 1.5rem;
        line-height: 1.3em;
    }
}

html.cas .post-full-content h4 {
    margin: 0.5em 0 0.2em;
    font-size: 1.5625rem;
    font-weight: 600;
}

html.cas .post-full-content h2 + h4 {
    margin-top: 0.7em;
}

html.cas .post-full-content h3 + h4 {
    margin-top: 0;
}

@media (max-width: 800px) {
    html.cas .post-full-content h4 {
        margin-bottom: 0.3em;
        font-size: 1.5rem;
        line-height: 1.3em;
    }
}

html.cas .post-full-content h5 {
    display: block;
    margin: 0.5em 0;
    padding: 0.4em 1em 0.9em;
    border: 0;
    color: var(--blue);
    font-family: Georgia, serif;
    font-size: 2rem;
    line-height: 1.35em;
    text-align: center;
}

@media (min-width: 1180px) {
    html.cas .post-full-content h5 {
        max-width: 1060px;
        width: 100vw;
    }
}

@media (max-width: 800px) {
    html.cas .post-full-content h5 {
        margin-bottom: 1em;
        margin-left: 1.3em;
        padding: 0 0 0.5em;
        font-size: 1.5rem;
        text-align: initial;
    }
}

html.cas .post-full-content h6 {
    margin: 0.5em 0 0.2em 0;
    font-size: 1.25rem;
    font-weight: 700;
}

@media (max-width: 800px) {
    html.cas .post-full-content h6 {
        font-size: 1.125rem;
        line-height: 1.4em;
    }
}

html.cas .footnotes-sep {
    margin-bottom: 30px;
}

html.cas .footnotes {
    font-size: 0.9375rem;
}

html.cas .footnotes p {
    margin: 0;
}

html.cas .footnote-backref {
    color: var(--blue) !important;
    font-size: 0.75rem;
    font-weight: bold;
    text-decoration: none !important;
    box-shadow: none !important;
}

/* Some grouped styles for smaller viewports */
@media (max-width: 1170px) {
    html.cas .post-full-image {
        margin: 25px -6vw 50px;
        border-radius: 0;
    }

    html.cas .post-full-image img {
        max-width: 1170px;
    }
}

@media (max-width: 700px) {
    html.cas .post-full-image {
        margin: 25px -5vw;
    }
}

@media (max-width: 500px) {
    html.cas .post-full-meta {
        font-size: 0.75rem;
        line-height: 1.3em;
    }

    html.cas .post-full-title {
        margin-top: 0.2em;
        font-size: 2.625rem;
        line-height: 1.05em;
    }

    html.cas .post-full-image {
        margin-top: 5px;
        margin-bottom: 5vw;
    }

    html.cas .post-full-content {
        padding: 0;
    }

    html.cas .post-full-content:before,
    html.cas .post-full-content:after {
        display: none;
    }
}

/* Tables */
html.cas .post-full-content table {
    display: inline-block;
    overflow-x: auto;
    margin: 0.5em 0 2.5em;
    max-width: 100%;
    width: auto;
    border-spacing: 0;
    border-collapse: collapse;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
    font-size: 1rem;
    white-space: nowrap;
    vertical-align: top;
}

html.cas .post-full-content table {
    -webkit-overflow-scrolling: touch;
    background: radial-gradient(ellipse at left, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0) 75%) 0 center,
        radial-gradient(ellipse at right, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0) 75%) 100% center;
    background-attachment: scroll, scroll;
    background-size: 10px 100%, 10px 100%;
    background-repeat: no-repeat;
}

html.cas .post-full-content table td:first-child {
    background-image: linear-gradient(to right, rgba(255, 255, 255, 1) 50%, rgba(255, 255, 255, 0) 100%);
    background-size: 20px 100%;
    background-repeat: no-repeat;
}

html.cas .post-full-content table td:last-child {
    background-image: linear-gradient(to left, rgba(255, 255, 255, 1) 50%, rgba(255, 255, 255, 0) 100%);
    background-position: 100% 0;
    background-size: 20px 100%;
    background-repeat: no-repeat;
}

html.cas .post-full-content table th {
    color: var(--darkgrey);
    font-size: 0.75rem;
    font-weight: 700;
    letter-spacing: 0.2px;
    text-align: left;
    text-transform: uppercase;
    background-color: color-mod(var(--whitegrey) l(+4%));
}

html.cas .post-full-content table th,
html.cas .post-full-content table td {
    padding: 6px 12px;
    border: color-mod(var(--whitegrey) l(-1%) s(-5%)) 1px solid;
}

/* 7.1. Post Byline
/* ---------------------------------------------------------- */

html.cas .post-full-byline {
    display: flex;
    justify-content: space-between;
    margin: 35px 0 0;
    padding-top: 15px;
    border-top: 1px solid color-mod(var(--lightgrey) l(+10%));
}

html.cas .post-full-byline-content {
    flex-grow: 1;
    display: flex;
    align-items: flex-start;
}

html.cas .post-full-byline-content .author-list {
    justify-content: flex-start;
    padding: 0 12px 0 0;
}

html.cas .post-full-byline-meta {
    margin: 2px 0 0;
    color: color-mod(var(--midgrey) l(+10%));
    font-size: 0.75rem;
    line-height: 1.2em;
    letter-spacing: 0.2px;
    text-transform: uppercase;
}

html.cas .post-full-byline-meta h4 {
    margin: 0 0 3px;
    font-size: 0.8125rem;
    line-height: 1.4em;
    font-weight: 500;
}

html.cas .post-full-byline-meta h4 a {
    color: color-mod(var(--darkgrey) l(+10%));
}

html.cas .post-full-byline-meta h4 a:hover {
    color: var(--darkgrey);
}

html.cas .post-full-byline-meta .bull {
    display: inline-block;
    margin: 0 4px;
    opacity: 0.6;
}

html.cas .author-avatar {
    display: block;
    overflow: hidden;
    margin: 0 -4px;
    width: 40px;
    height: 40px;
    border: #fff 2px solid;
    border-radius: 100%;
    transition: all 0.5s cubic-bezier(0.4, 0.01, 0.165, 0.99) 700ms;
}

html.cas .author-list-item .author-card {
    position: absolute;
    bottom: 130%;
    left: 50%;
    z-index: 600;
    display: flex;
    justify-content: space-between;
    margin-left: -200px;
    width: 400px;
    font-size: 0.875rem;
    line-height: 1.5em;
    background: white;
    border-radius: 3px;
    box-shadow: rgba(39, 44, 49, 0.08) 0 12px 26px, rgba(39, 44, 49, 0.06) 1px 3px 8px;
    opacity: 0;
    transition: all 0.35s cubic-bezier(0.4, 0.01, 0.165, 0.99);
    transform: scale(0.98) translateY(15px);
    pointer-events: none;
}

html.cas .author-list-item .author-card:before {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    display: block;
    margin-left: -8px;
    width: 0;
    height: 0;
    border-top: 8px solid #fff;
    border-right: 8px solid transparent;
    border-left: 8px solid transparent;
}

html.cas .author-list-item .author-card.hovered {
    opacity: 1;
    transform: scale(1) translateY(0px);
    pointer-events: auto;
}

html.cas .author-card {
    padding: 20px 20px 22px;
}

html.cas .author-card .author-info {
    flex: 1 1 auto;
    padding: 0 0 0 20px;
}

html.cas .author-card .author-info h2,
html.cas .author-card .author-info .author-name {
    margin: 8px 0 0;
    font-size: 1rem;
    color: #000000;
}

html.cas .author-card .author-info p {
    margin: 4px 0 0;
    color: color-mod(var(--midgrey) l(-10%));
}

html.cas .author-card .author-info .bio h2 {
    margin-top: 0;
}

html.cas .author-card .author-info .bio p {
    margin-top: 0.8em;
}

html.cas .author-card .author-profile-image {
    flex: 0 0 60px;
    margin: 0;
    width: 60px;
    height: 60px;
    border: none;
}

html.cas .basic-info .avatar-wrapper {
    position: relative;
    margin: 0;
    width: 60px;
    height: 60px;
    border: none;
    background: rgba(229, 239, 245, 0.1);
}

html.cas .basic-info .avatar-wrapper svg {
    margin: 0;
    width: 60px;
    height: 60px;
    opacity: 0.15;
}

@media (max-width: 1170px) {
    html.cas .author-list-item .author-card {
        margin-left: -50px;
        width: 430px;
    }

    html.cas .author-list-item .author-card:before {
        left: 50px;
    }
}

@media (max-width: 650px) {
    html.cas .author-list-item .author-card {
        display: none;
    }
}

@media (max-width: 500px) {
    html.cas .author-avatar {
        width: 36px;
        height: 36px;
    }

    html.cas .post-full-byline {
        margin-top: 20px;
    }

    html.cas .post-full-byline-meta {
        font-size: 0.75rem;
    }

    html.cas .post-full-byline-meta h4 {
        margin-bottom: 2px;
        font-size: 0.75rem;
    }
}

/* 7.2. Members Subscribe Form
/* ---------------------------------------------------------- */
html.cas .subscribe-form {
    margin: 1.5em 0;
    padding: 6.5vw 7vw 8vw;
    border: color-mod(var(--lightgrey) l(+10%)) 1px solid;
    text-align: center;
    background: linear-gradient(color-mod(var(--whitegrey) l(+6%)), color-mod(var(--whitegrey) l(+4%)));
    border-radius: 3px;
}

html.cas .subscribe-form-title {
    margin: 0 0 3px 0;
    padding: 0;
    color: var(--darkgrey);
    font-size: 2.1875rem;
    line-height: 1;
    font-weight: 600;
}

html.cas .subscribe-form-description {
    margin-bottom: 0.2em 0 1em;
    color: var(--midgrey);
    font-size: 1.3125rem;
    line-height: 1.55em;
}

html.cas .subscribe-form form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    max-width: 460px;
}

html.cas .subscribe-form .form-group {
    align-self: stretch;
    display: flex;
}

html.cas .subscribe-email {
    display: block;
    padding: 10px;
    width: 100%;
    border: color-mod(var(--lightgrey) l(+7%)) 1px solid;
    color: var(--midgrey);
    font-size: 1.125rem;
    line-height: 1em;
    font-weight: normal;
    user-select: text;
    border-radius: 5px;
    transition: border-color 0.15s linear;

    -webkit-appearance: none;
}

html.cas .subscribe-email:focus {
    outline: 0;
    border-color: color-mod(var(--lightgrey) l(-2%));
}

html.cas .subscribe-form button {
    position: relative;
    display: inline-block;
    margin: 0 0 0 10px;
    padding: 0 20px;
    height: 43px;
    outline: none;
    color: #fff;
    font-size: 0.9375rem;
    line-height: 39px;
    font-weight: 400;
    text-align: center;
    background: linear-gradient(
        color-mod(var(--blue) whiteness(+7%)),
        color-mod(var(--blue) lightness(-7%) saturation(-10%)) 60%,
        color-mod(var(--blue) lightness(-7%) saturation(-10%)) 90%,
        color-mod(var(--blue) lightness(-4%) saturation(-10%))
    );
    border-radius: 5px;

    -webkit-font-smoothing: subpixel-antialiased;
}

html.cas .subscribe-form button:active,
html.cas .subscribe-form button:focus {
    background: color-mod(var(--blue) lightness(-9%) saturation(-10%));
}

html.cas .subscribe-form .button-loader,
html.cas .subscribe-form .message-success,
html.cas .subscribe-form .message-error {
    display: none;
}

html.cas .subscribe-form .loading .button-content {
    visibility: hidden;
}

html.cas .subscribe-form .loading .button-loader {
    position: absolute;
    top: 0;
    left: 50%;
    display: inline-block;
    margin-left: -19px;
    transform: scale(0.7);
}

html.cas .subscribe-form .button-loader svg path,
html.cas .subscribe-form .button-loader svg rect {
    fill: #fff;
}

html.cas .subscribe-form .success .message-success,
html.cas .subscribe-form .invalid .message-error,
html.cas .subscribe-form .error .message-error {
    margin: 1em auto 0;
    max-width: 400px;
    color: var(--red);
    font-size: 1rem;
    line-height: 1.5em;
    text-align: center;
}

html.cas .subscribe-form .success .message-success {
    display: block;
    color: color-mod(var(--green) l(-5%));
}

html.cas .subscribe-form .invalid .message-error,
html.cas .subscribe-form .error .message-error {
    display: block;
}

@media (max-width: 650px) {
    html.cas .subscribe-form-title {
        font-size: 1.5rem;
    }

    html.cas .subscribe-form-description {
        font-size: 1rem;
    }
}

@media (max-width: 500px) {
    html.cas .subscribe-form form {
        flex-direction: column;
    }

    html.cas .subscribe-form .form-group {
        flex-direction: column;
        width: 100%;
    }

    html.cas .subscribe-form button {
        margin: 10px 0 0 0;
        width: 100%;
    }
}

/* 7.3. Comments
/* ---------------------------------------------------------- */

html.cas .post-full-comments {
    margin: 0 auto;
    max-width: 840px;
}

/* 7.4. Related posts
/* ---------------------------------------------------------- */

html.cas .read-next {
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    background: color-mod(var(--darkgrey) l(-5%));
}

html.cas .read-next-feed {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -25px;
    padding: 60px 0 0 0;
}

html.cas .read-next .post-card {
    padding-bottom: 0;
    border-bottom: none;
}

html.cas .read-next .post-card:after {
    display: none;
}

html.cas .read-next .post-card-primary-tag {
    color: #fff;
    opacity: 0.6;
}

html.cas .read-next .post-card-title {
    color: #fff;
    opacity: 0.8;
    transition: all 0.2s ease-in-out;
}

html.cas .read-next .post-card:hover .post-card-image {
    opacity: 1;
}

html.cas .read-next .post-card-excerpt {
    color: rgba(255, 255, 255, 0.6);
}

html.cas .read-next .static-avatar {
    border-color: #000;
}

html.cas .read-next .post-card-byline-content {
    color: rgba(255, 255, 255, 0.6);
}

html.cas .read-next .post-card-byline-content a {
    color: rgba(255, 255, 255, 0.8);
}

html.cas .read-next-card {
    position: relative;
    flex: 0 1 326px;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    margin: 0 25px 50px;
    padding: 25px;
    background: linear-gradient(color-mod(var(--darkgrey) l(+2%)), color-mod(var(--darkgrey) l(-5%)));
    border-radius: 3px;
}

html.cas .read-next-card a {
    transition: all 0.2s ease-in-out;
}

html.cas .read-next-card a:hover {
    text-decoration: none;
}

html.cas .read-next-card-header h3 {
    margin: 0;
    color: rgba(255, 255, 255, 0.6);
    font-size: 0.75rem;
    line-height: 1em;
    font-weight: 300;
    letter-spacing: 0.4px;
    text-transform: uppercase;
}

html.cas .read-next-card-header h3 a {
    color: #fff;
    font-weight: 500;
    text-decoration: none;
    opacity: 0.8;
}

html.cas .read-next-card-header h3 a:hover {
    opacity: 1;
}

html.cas .read-next-card-content {
    font-size: 1.0625rem;
}

html.cas .read-next-card-content ul {
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 0;
    list-style: none;
}

html.cas .read-next-card-content li {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 0;
    padding: 20px 0;
    border-bottom: rgba(255, 255, 255, 0.1);
}

html.cas .read-next-card-content li:last-of-type {
    padding-bottom: 5px;
    border: none;
}

html.cas .read-next-card-content h4 {
    margin: 0;
    font-size: 1rem;
    line-height: 1.35em;
    font-weight: 600;
}

html.cas .read-next-card-content li a {
    display: block;
    color: #fff;
    opacity: 0.8;
}

html.cas .read-next-card-content li a:hover {
    opacity: 1;
}

html.cas .read-next-card-excerpt {
    overflow: hidden;
    max-width: 100%;
    font-size: 0.875rem;
    line-height: 1.2em;
    text-overflow: ellipsis;
}

html.cas .read-next-card-meta {
    margin-top: 2px;
    font-size: 0.75rem;
    line-height: 1.4em;
    font-weight: 400;
}

html.cas .read-next-card-meta p {
    margin: 0;
    color: rgba(255, 255, 255, 0.6);
}

html.cas .read-next-card-footer {
    position: relative;
    margin: 40px 0 5px;
}

html.cas .read-next-card-footer a {
    padding: 7px 12px 8px 14px;
    border: 1px solid rgba(255, 255, 255, 0.6);
    color: rgba(255, 255, 255, 0.6);
    font-size: 0.8125rem;
    border-radius: 999px;
    transition: all 0.35s ease-in-out;
}

html.cas .read-next-card-footer a:hover {
    border-color: var(--yellow);
    color: var(--yellow);
    text-decoration: none;
}

@media (max-width: 1170px) {
    html.cas .read-next-card {
        flex: 1 1 261px;
        margin-bottom: 5vw;
    }
}

@media (max-width: 650px) {
    html.cas .read-next-feed {
        flex-direction: column;
        padding: 25px 0 0;
    }

    html.cas .read-next-card {
        flex: 1 1 auto;
        margin: 0 25px;
        padding: 0;
        background: none;
    }

    html.cas .read-next .post-card {
        flex: 1 1 auto;
        margin: 25px;
        padding: 25px 0 0;
        border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    }
}

/* 7.5. Koenig Styles
/* ---------------------------------------------------------- */

html.cas .post-content {
    display: flex;
    flex-direction: column;
    align-items: center;
}

html.cas .post-full-content .kg-image {
    margin: 0 auto;
    max-width: 100%;
}

/* Preventing full-width image overlap with post image.  */
html.cas .post-full-image + .post-full-content .kg-content *:first-child .kg-image {
    width: 100%;
}

html.cas .post-full-content .kg-width-wide .kg-image {
    max-width: 1040px;
}

html.cas .post-full-content .kg-width-full .kg-image {
    max-width: 100vw;
}

html.cas .post-full-content figure {
    margin: 0.8em 0 2.3em;
}

html.cas .post-full-content h1 + figure,
html.cas .post-full-content h2 + figure,
html.cas .post-full-content h3 + figure,
html.cas .post-full-content h4 + figure {
    margin-top: 2em;
}

html.cas .post-full-content figure img {
    margin: 0;
}

html.cas .post-full-content figcaption {
    margin: 1em auto 0;
    color: color-mod(var(--midgrey) l(-10%));
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
    font-size: 75%;
    line-height: 1.5em;
    text-align: center;
    max-width: 1040px;
}

html.cas .kg-width-full figcaption {
    padding: 0 1.5em;
}

html.cas .kg-embed-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

html.cas .kg-embed-card .fluid-width-video-wrapper {
    margin: 0;
}

@media (max-width: 1040px) {
    html.cas .post-full-content .kg-width-full .kg-image {
        width: 100vw;
    }
}

html.cas .kg-gallery-container {
    display: flex;
    flex-direction: column;
    max-width: 1040px;
    width: 100vw;
}

html.cas .kg-gallery-row {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

html.cas .kg-gallery-image img {
    display: block;
    margin: 0;
    width: 100%;
    height: 100%;
}

html.cas .kg-gallery-row:not(:first-of-type) {
    margin: 0.75em 0 0 0;
}

html.cas .kg-gallery-image:not(:first-of-type) {
    margin: 0 0 0 0.75em;
}

html.cas .kg-gallery-card + .kg-image-card.kg-width-wide,
html.cas .kg-gallery-card + .kg-gallery-card,
html.cas .kg-image-card.kg-width-wide + .kg-gallery-card,
html.cas .kg-image-card.kg-width-wide + .kg-image-card.kg-width-wide {
    margin: -2.25em 0 3em;
}

/* keep existing <pre> styles for code cards with captions */
html.cas .kg-code-card {
    width: 100%;
}

html.cas .kg-code-card pre {
    margin: 0;
}

html.cas .kg-bookmark-card {
    width: 100%;
}

html.cas .kg-card + .kg-bookmark-card {
    margin-top: 0;
}

html.cas .post-full-content .kg-bookmark-container {
    display: flex;
    min-height: 148px;
    color: var(--darkgrey);
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
    text-decoration: none;
    border-radius: 3px;
    box-shadow: 0 2px 5px -1px rgba(0, 0, 0, 0.15), 0 0 1px rgba(0, 0, 0, 0.09);
}

html.cas .post-full-content .kg-bookmark-container:hover {
    color: var(--darkgrey);
    text-decoration: none;
    box-shadow: 0 2px 5px -1px rgba(0, 0, 0, 0.15), 0 0 1px rgba(0, 0, 0, 0.09);
}

html.cas .kg-bookmark-content {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 20px;
}

html.cas .kg-bookmark-title {
    color: color-mod(var(--midgrey) l(-30%));
    font-size: 1rem;
    line-height: 1.5em;
    font-weight: 600;
    transition: color 0.2s ease-in-out;
}

html.cas .post-full-content .kg-bookmark-container:hover .kg-bookmark-title {
    color: var(--blue);
}

html.cas .kg-bookmark-description {
    display: -webkit-box;
    overflow-y: hidden;
    margin-top: 12px;
    max-height: 48px;
    color: color-mod(var(--midgrey) l(-10%));
    font-size: 0.9375rem;
    line-height: 1.5em;
    font-weight: 400;

    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

html.cas .kg-bookmark-thumbnail {
    position: relative;
    min-width: 33%;
    max-height: 100%;
}

html.cas .kg-bookmark-thumbnail img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 0 3px 3px 0;

    object-fit: cover;
}

html.cas .kg-bookmark-metadata {
    display: none;
    flex-wrap: wrap;
    align-items: center;
    margin-top: 14px;
    color: var(--darkgrey);
    font-size: 0.9375rem;
    font-weight: 500;
}

html.cas .post-full-content .kg-bookmark-icon {
    margin-right: 8px;
    width: 22px;
    height: 22px;
}

html.cas .kg-bookmark-author {
    line-height: 1.5em;
}

html.cas .kg-bookmark-author:after {
    content: "•";
    margin: 0 6px;
}

html.cas .kg-bookmark-publisher {
    overflow: hidden;
    max-width: 240px;
    line-height: 1.5em;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: color-mod(var(--midgrey) l(-10%));
    font-weight: 400;
}

@media (max-width: 800px) {
    html.cas .post-full-content figure {
        margin: 0.2em 0 1.3em;
    }

    html.cas .post-full-content h1 + figure,
    html.cas .post-full-content h2 + figure,
    html.cas .post-full-content h3 + figure,
    html.cas .post-full-content h4 + figure {
        margin-top: 0.9em;
    }
}

@media (max-width: 500px) {
    html.cas .post-full-content .kg-width-wide,
    html.cas .post-full-content .kg-width-full {
        margin-right: -5vw;
        margin-left: -5vw;
    }

    html.cas .post-full-content figcaption {
        margin-bottom: 0.4em;
    }

    html.cas .post-full-content .kg-bookmark-container {
        flex-direction: column;
    }

    html.cas .kg-bookmark-title,
    html.cas .kg-bookmark-description,
    html.cas .kg-bookmark-metadata {
        font-size: 0.875rem;
        line-height: 1.5em;
    }

    html.cas .post-full-content .kg-bookmark-icon {
        width: 18px;
        height: 18px;
    }

    html.cas .kg-bookmark-thumbnail {
        order: 1;
        min-height: 160px;
        width: 100%;
    }

    html.cas .kg-bookmark-thumbnail img {
        border-radius: 3px 3px 0 0;
    }

    html.cas .kg-bookmark-content {
        order: 2;
    }
}

/* 8. Author Template
/* ---------------------------------------------------------- */
html.cas .author-header {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 10vw 0 10px;
}

html.cas .site-archive-header .author-header {
    align-items: center;
}

html.cas .site-archive-header .no-image .author-header {
    padding-bottom: 20px;
}

html.cas .author-header-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 5px 0 0 30px;
}

html.cas .site-header-content .author-profile-image {
    z-index: 10;
    flex-shrink: 0;
    margin: -4px 0 0;
    width: 110px;
    height: 110px;
    box-shadow: rgba(255, 255, 255, 0.1) 0 0 0 6px;
}

html.cas .author-header-content .author-bio {
    z-index: 10;
    flex-shrink: 0;
    margin: 6px 0 0;
    max-width: 46em;
    font-size: 1.25rem;
    line-height: 1.3em;
    font-weight: 400;
    opacity: 0.8;
}

html.cas .author-header-content .author-meta {
    z-index: 10;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    margin: 0 0 0 1px;
    font-size: 0.75rem;
    font-weight: 400;
    letter-spacing: 0.2px;
    text-transform: uppercase;
    white-space: nowrap;
}

html.cas .author-header-content .social-link:first-of-type {
    padding-left: 4px;
}

html.cas .no-image .author-header-content .author-bio {
    color: var(--midgrey);
    opacity: 1;
}

html.cas .no-image .author-header-content .author-meta {
    color: var(--midgrey);
    opacity: 1;
}

html.cas .author-social-link a {
    color: #fff;
    font-weight: 600;
}

html.cas .no-image .author-social-link a {
    color: var(--darkgrey);
}

html.cas .author-social-link a:hover {
    opacity: 1;
}

html.cas .author-social-link {
    display: inline-block;
    margin: 0;
    padding: 6px 0;
}

html.cas .author-location + .author-stats:before,
html.cas .author-stats + .author-social-link:before,
html.cas .author-social-link + .author-social-link:before {
    content: "\2022";
    display: inline-block;
    margin: 0 12px;
    color: #fff;
    opacity: 0.6;
}

html.cas .no-image .author-location + .author-stats:before,
html.cas .no-image .author-stats + .author-social-link:before,
html.cas .no-image .author-social-link + .author-social-link:before {
    color: var(--midgrey);
}

@media (max-width: 700px) {
    html.cas .author-location,
    html.cas .author-stats,
    html.cas .author-stats + .author-social-link:first-of-type:before {
        display: none;
    }
}

@media (max-width: 500px) {
    html.cas .author-header {
        padding: 10px 0 0;
    }

    html.cas .no-image .author-header {
        padding-bottom: 10px;
    }

    html.cas .author-header-content {
        align-items: center;
        margin: 16px 0 0 0;
    }

    html.cas .site-header-content .author-profile-image {
        width: 96px;
        height: 96px;
    }

    html.cas .author-header-content .author-bio {
        font-size: 1.125rem;
        line-height: 1.3em;
        letter-spacing: 0;
        text-align: center;
    }

    html.cas .author-header-content .author-meta {
        margin-top: 8px;
    }

    html.cas .author-location + .author-stats:before,
    html.cas .author-stats + .author-social-link:before,
    html.cas .author-social-link + .author-social-link:before {
        display: inline;
        margin: 0 6px;
    }
}

/* 9. Error Template
/* ---------------------------------------------------------- */

html.cas .error-content {
    padding: 14vw 4vw 6vw;
}

.not-found-page {
    padding-top: 10rem;
}

.not-found-page .smile-icon {
    margin: auto;
    height: 100px;
    object-fit: contain;
    margin-bottom: 1rem;
}

html.cas .site-nav-center {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 0;
    text-align: center;
}

html.cas .site-nav-center .site-nav-logo {
    margin-right: 0;
}

.not-found-page .error-message {
    padding-bottom: 10vw;
    border-bottom: 1px solid color-mod(var(--lightgrey) l(+10%));
    text-align: center;
}

.not-found-page .error-code {
    margin: 0;
    color: var(--lightgrey);
    font-size: 12vw;
    line-height: 1em;
    letter-spacing: -5px;
    opacity: 0.75;
}

.not-found-page .error-description {
    margin: 0;
    color: var(--midgrey);
    font-size: 1.875rem;
    line-height: 1.3em;
    font-weight: 400;
    margin-bottom: 3rem;
}

.not-found-page .error-link {
    display: inline-block;
    margin-top: 5px;
}

@media (min-width: 940px) {
    html.cas .error-content .post-card {
        margin-bottom: 0;
        padding-bottom: 0;
        border-bottom: none;
    }
}

@media (max-width: 800px) {
    html.cas .error-content {
        padding-top: 24vw;
    }

    html.cas .error-code {
        font-size: 7rem;
    }

    html.cas .error-message {
        padding-bottom: 16vw;
    }

    html.cas .error-description {
        margin: 5px 0 0 0;
        font-size: 1.125rem;
    }

    .not-found-page .inner {
        padding: 0 2rem;
    }

    .not-found-page .error-description {
        font-size: 1.575rem;
    }

    .not-found-page .error-code {
        font-size: 100px;
        margin-bottom: 2rem;
    }
}

@media (max-width: 500px) {
    html.cas .error-content {
        padding-top: 28vw;
    }

    html.cas .error-message {
        padding-bottom: 14vw;
    }
}

/* 10. Subscribe Message and Overlay
/* ---------------------------------------------------------- */

html.cas .subscribe-notification {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 9000;
    padding: 20px 0;
    color: #fff;
    text-align: center;
    background: var(--green);
    transition: all 0.35s cubic-bezier(0.19, 1, 0.22, 1) 0.5s;
    transform: translateY(-175%);
}

html.cas .subscribe-notification.subscribe-failure-message {
    background: var(--red);
}

html.cas .subscribe-success .subscribe-success-message {
    visibility: visible;
    transform: translateY(0);
}

html.cas .subscribe-failure .subscribe-failure-message {
    visibility: visible;
    transform: translateY(0);
}

html.cas .subscribe-notification.close {
    visibility: hidden;
    transition: all 1.35s cubic-bezier(0.19, 1, 0.22, 1);
    transform: translateY(-175%);
}

html.cas .subscribe-notification .subscribe-close-button {
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
}

html.cas .subscribe-notification .subscribe-close-button:before,
html.cas .subscribe-notification .subscribe-close-button:after {
    top: 31px;
    right: 20px;
    width: 18px;
    height: 2px;
}

html.cas .subscribe-close-overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: block;
}

html.cas .subscribe-close-button {
    position: absolute;
    top: 16px;
    right: 20px;
    z-index: 2000;
    display: block;
    width: 40px;
    height: 40px;
}

html.cas .subscribe-close-button:before {
    content: "";
    position: absolute;
    top: 20px;
    right: 4px;
    display: block;
    width: 32px;
    height: 1px;
    background: #fff;
    opacity: 0.8;
    transform: rotate(45deg);
}

html.cas .subscribe-close-button:after {
    content: "";
    position: absolute;
    top: 20px;
    right: 4px;
    display: block;
    width: 32px;
    height: 1px;
    background: #fff;
    opacity: 0.8;
    transform: rotate(-45deg);
}

html.cas .subscribe-close-overlay:hover,
html.cas .subscribe-close-button:hover {
    cursor: default;
}

html.cas .subscribe-overlay {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 2000;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(9, 10, 11, 0.97);
    opacity: 0;
    transition: opacity 0.2s ease-in;
    pointer-events: none;
}

html.cas .subscribe-overlay:target {
    z-index: 2001;
    opacity: 1;
    pointer-events: auto;
}

html.cas .subscribe-overlay-content {
    position: relative;
    margin: 0 0 5vw 0;
    padding: 4vw;
    color: #fff;
    text-align: center;
}

html.cas .subscribe-overlay .subscribe-form {
    border: none;
    color: #fff;
    background: none;
}

html.cas .subscribe-overlay-logo {
    position: fixed;
    top: 23px;
    left: 30px;
    height: 30px;
}

html.cas .subscribe-overlay-title {
    display: inline-block;
    margin: 0 0 10px 0;
    font-size: 3.25rem;
    line-height: 1.15em;
}

html.cas .subscribe-overlay-description {
    margin: 0 auto 50px;
    max-width: 650px;
    color: #fff;
    font-family: Georgia, serif;
    font-size: 1.5rem;
    line-height: 1.3em;
    font-weight: 300;
    opacity: 0.8;
}

html.cas .subscribe-overlay form {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    max-width: 540px;
}

html.cas .subscribe-overlay .form-group {
    flex-grow: 1;
}

html.cas .subscribe-overlay .subscribe-email {
    display: block;
    padding: 14px 20px;
    width: 100%;
    border: none;
    color: var(--midgrey);
    font-size: 1.25rem;
    line-height: 1em;
    font-weight: normal;
    letter-spacing: 0.5px;
    user-select: text;
    border-radius: 8px;
    transition: border-color 0.15s linear;

    -webkit-appearance: none;
}

html.cas .subscribe-email:focus {
    outline: 0;
    border-color: color-mod(var(--lightgrey) l(-2%));
}

html.cas .subscribe-overlay button {
    display: inline-block;
    margin: 0 0 0 15px;
    padding: 0 25px;
    height: 52px;
    outline: none;
    color: #fff;
    font-size: 1.0625rem;
    line-height: 38px;
    font-weight: 400;
    text-align: center;
    background: linear-gradient(
        color-mod(var(--blue) whiteness(+7%)),
        color-mod(var(--blue) lightness(-7%) saturation(-10%)) 60%,
        color-mod(var(--blue) lightness(-7%) saturation(-10%)) 90%,
        color-mod(var(--blue) lightness(-4%) saturation(-10%))
    );
    border-radius: 8px;

    -webkit-font-smoothing: subpixel-antialiased;
}

html.cas .subscribe-overlay button:active,
html.cas .subscribe-overlay button:focus {
    background: color-mod(var(--blue) lightness(-9%) saturation(-10%));
}

html.cas .subscribe-overlay .loading .button-loader {
    top: 5px;
}

@media (max-width: 500px) {
    html.cas .subscribe-overlay button {
        margin: 12px 0 0;
    }
}

/* 11. Site Footer
/* ---------------------------------------------------------- */

html.cas .site-footer {
    position: relative;
    padding-top: 20px;
    padding-bottom: 60px;
    color: #fff;
    background: color-mod(var(--darkgrey) l(-5%));
}

html.cas .site-footer-content {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    color: rgba(255, 255, 255, 0.7);
    font-size: 0.8125rem;
}

html.cas .site-footer-content a {
    color: rgba(255, 255, 255, 0.7);
}

html.cas .site-footer-content a:hover {
    color: rgba(255, 255, 255, 1);
    text-decoration: none;
}

html.cas .site-footer-nav {
    display: flex;
}

html.cas .site-footer-nav a {
    position: relative;
    margin-left: 20px;
}

html.cas .site-footer-nav a:before {
    content: "";
    position: absolute;
    top: 11px;
    left: -11px;
    display: block;
    width: 2px;
    height: 2px;
    background: #fff;
    border-radius: 100%;
}

html.cas .site-footer-nav a:first-of-type:before {
    display: none;
}

@media (max-width: 650px) {
    html.cas .site-footer-content {
        flex-direction: column;
    }

    html.cas .site-footer-nav a:first-child {
        margin-left: 0;
    }
}

/* 13. Whatsapp ChatBot Landing
/* ---------------------------------------------------------- */

.whatsapp-chatbot .landing {
    padding: 2.5rem 0;
    background: url("/whatsappchatbotBanner.png");
    background-size: contain;
}

.whatsapp-chatbot .landing-title {
    font-size: 48px;
    font-style: normal;
    font-weight: 700;

    line-height: 50px;
    letter-spacing: 0.02em;

    text-align: left;

    background: linear-gradient(90.11deg, #3364fa 0.09%, #25cf41 99.96%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

    margin-bottom: 0.25rem;
}

.whatsapp-chatbot .landing-sub-title {
    font-size: 40px;
    font-style: normal;
    font-weight: 400;

    line-height: 60px;
    letter-spacing: 0.02em;

    text-align: left;
    color: #000000;
}

.whatsapp-chatbot .landing-description {
    font-size: 18px;
    font-style: normal;
    font-weight: 400;

    line-height: 34px;
    letter-spacing: 0.02em;

    text-align: left;
    margin-top: 1rem;
}

.whatsapp-chatbot .landing-banner {
    max-width: 600px;
    object-fit: contain;
}

.whatsapp-chatbot .landing-top-cta {
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 25px;
    letter-spacing: 0.02em;
    text-align: center;
    margin-top: 2rem;
}

.whatsapp-chatbot .buisness {
    font-size: 30px;
    font-style: normal;
    font-weight: 500;

    line-height: 38px;
    letter-spacing: 0em;

    text-align: center;

    color: #283560;
}

.whatsapp-chatbot .images-block {
    margin: 3rem auto;
}

.whatsapp-chatbot .images-block img {
    margin-right: 5rem;
    width: 120px;
    height: 2.5rem;
    object-fit: contain;
}

.whatsapp-chatbot .cta-cards {
    margin: 5rem auto;
    display: flex;
}

.whatsapp-chatbot .cta-cards .card {
    padding: 2rem;
    padding-bottom: 0;
    background: #f2fffc;
    flex: 50%;
}

/* Card Colors */
.whatsapp-chatbot .card.bg-blue {
    background: #f2fffc;
}

.whatsapp-chatbot .card.bg-sky-blue {
    background: #f3fcff;
}

.whatsapp-chatbot .card.bg-yellow {
    background: #fefff3;
}

.whatsapp-chatbot .card.bg-pink {
    background: #faf6ff !important;
}

.whatsapp-chatbot .card.bg-light-pink {
    background: #fff8f8;
}

.whatsapp-chatbot .card.bg-dark-yellow {
    background: #f6fff2;
}

.whatsapp-chatbot .cta-cards .card .card-title {
    font-size: 26px;
    font-style: normal;
    font-weight: 700;

    line-height: 34px;
    letter-spacing: 0.03em;

    text-align: center;
    color: #000000;

    margin-top: 0;
}

.whatsapp-chatbot .cta-cards .card .card-description {
    font-family: Google Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: 0.03em;
    text-align: center;
}

.whatsapp-chatbot .cta-cards .card .card-image {
    object-fit: contain;
    margin: auto;
    height: 430px;
    margin-top: 3rem;
}

.whatsapp-chatbot .benefits .title {
    font-size: 36px;
    font-style: normal;
    font-weight: 700;

    line-height: 60px;
    letter-spacing: 0em;

    text-align: center;

    color: #283560;
}

.whatsapp-chatbot .benefits .sub-title {
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: 46px;
    letter-spacing: 0.02em;

    width: 20%;
    margin: auto;
    text-align: center;

    background: linear-gradient(90.11deg, #3364fa 0.09%, #25cf41 99.96%);

    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.whatsapp-chatbot .benefits {
    margin-bottom: 3rem;
}

.whatsapp-chatbot .benefits .card {
    padding: 2rem;
    text-align: center;
    flex: 25%;
    margin-right: 2rem;
    margin-bottom: 2rem;
    border-radius: 20px;
}

.whatsapp-chatbot .benefits .card:nth-of-type(3n) {
    margin-right: 0;
}

.whatsapp-chatbot .benefits .card .card-title {
    font-size: 26px;
    font-style: normal;
    font-weight: 700;

    line-height: 33px;
    letter-spacing: 0.02em;

    text-align: center;
}

.whatsapp-chatbot .benefits .card .card-description {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: 0.02em;
    text-align: center;
}

.whatsapp-chatbot .cta-cards .cta-button {
    background: transparent;
}

.whatsapp-chatbot .cta-cards .cta-button:hover {
    background: rgba(51, 100, 251, var(--tw-bg-opacity));
}

.whatsapp-chatbot .benefits .benefits-image {
    height: 80px;
    object-fit: contain;
}

.whatsapp-chatbot .demo {
    margin-top: 5rem;
}

.whatsapp-chatbot .demo .title {
    font-size: 38px;
    font-style: normal;
    font-weight: 500;

    line-height: 54px;
    letter-spacing: 0.02em;

    text-align: left;

    margin-top: 0;
}

.whatsapp-chatbot .demo .description {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
    letter-spacing: 0.04em;
    text-align: left;
    color: #666666;
    margin-bottom: 1.5rem;
}

.whatsapp-chatbot .demo .demo-img {
    max-width: 600px;
    object-fit: contain;
    flex: 45%;
}

.whatsapp-chatbot .demo .button-text {
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 23px;
    letter-spacing: 0.02em;
}

.whatsapp-chatbot .blog-sub-title {
    background: linear-gradient(90.11deg, #3364fa 0.09%, #25cf41 99.96%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

@media (max-width: 426px) {
    .whatsapp-chatbot .landing {
        padding: 0 10%;
        background-repeat: no-repeat;
        background-size: cover;
    }

    .whatsapp-chatbot .landing .flex {
        flex-flow: column;
    }

    .whatsapp-chatbot .landing .landing-inputs input {
        width: 100%;
    }

    .whatsapp-chatbot .images-block {
        padding: 0 10%;
        flex-flow: column;
        justify-content: center;
    }

    .whatsapp-chatbot .images-block img {
        width: 150px;
        margin: auto;
        margin-bottom: 2rem;
    }

    .whatsapp-chatbot .cta-cards {
        flex-flow: column;
    }

    .whatsapp-chatbot .benefits .flex {
        flex-flow: column;
    }

    .whatsapp-chatbot .get-started input {
        width: 100%;
        margin-bottom: 1rem;
    }

    .whatsapp-chatbot .get-started .check-tips {
        padding-right: 0;
        justify-content: space-around;
        flex-flow: column;
        margin-top: 2rem;
        align-items: start;
    }

    .whatsapp-chatbot .demo .flex {
        flex-flow: column;
        padding: 0 10%;
    }

    .whatsapp-chatbot .demo .demo-img {
        max-width: 100%;
        margin: 2rem 0;
    }

    .whatsapp-chatbot .landing .email-landing {
        flex-flow: row;
        margin-top: 1rem;
    }

    .whatsapp-chatbot .benefits .sub-title {
        width: 75%;
    }

    .whatsapp-chatbot .benefits .card {
        margin-right: 0;
        margin: 1.5rem 10% !important;
    }

    .whatsapp-chatbot .get-started .second-block {
        flex: auto;
    }

    .whatsapp-chatbot .get-started {
        padding-bottom: 3rem;
    }

    .whatsapp-chatbot .blog {
        padding-top: 0rem;
    }
}

/* 13. Integrations
/* ---------------------------------------------------------- */
.integrations .landing {
    padding: 2.5rem 0;
    background-image: url("/integrations_landing_banner.png");
    background-size: cover;
}

.integrations .landing .container {
    padding-bottom: 6rem;
}

.integrations .landing-title {
    font-size: 45px;
    font-style: normal;
    font-weight: 700;

    line-height: 50px;
    letter-spacing: 0.02em;

    text-align: left;

    background: linear-gradient(90.11deg, #3364fa 0.09%, #25cf41 99.96%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

    margin-bottom: 0.25rem;
}

.integrations .landing-sub-title {
    font-size: 40px;
    font-style: normal;
    font-weight: 400;

    line-height: 60px;
    letter-spacing: 0.02em;

    text-align: left;
    color: #000000;
}

.integrations .landing-description {
    font-size: 18px;
    font-style: normal;
    font-weight: 400;

    line-height: 34px;
    letter-spacing: 0.02em;

    text-align: left;
    margin-top: 1rem;
}

.integrations .landing-banner {
    max-width: 600px;
    object-fit: contain;
}

.integrations .landing-top-cta {
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 25px;
    letter-spacing: 0.02em;
    text-align: center;
    margin-top: 2rem;
}

.integrations .heading {
    font-family: Google Sans;
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: 60px;
    letter-spacing: 0em;
    color: #283560;
}

.integrations .colored-text {
    background: linear-gradient(90.11deg, #3364fa 0.09%, #25cf41 99.96%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.integrations .integrations-clients {
    position: relative;

    top: -120px;
}

.integrations .integrations-clients .container {
    background: #fff;
    border-radius: 20px;
    box-shadow: 0px 37px 68px 0px #0000000a;
}

.integrations .integrations-clients .images-block {
    display: flex;
    align-items: center;
    justify-content: center;
}

.integrations .integrations-clients .images-block img {
    width: 100px;
    height: 80px;
    margin: 1rem 2rem;
    object-fit: contain;
}

.integrations .ecommerce-block {
    background-image: url("/integration-client-logos.png");
    height: 630px;
    background-size: contain;
    background-position-x: 20px;
    background-repeat: no-repeat;
}

.integrations .ecommerce-block .heading {
    font-family: Google Sans;
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: 60px;
    letter-spacing: 0em;
    color: #283560;
    padding-top: 2rem;
}

.integrations .ecommerce-block .description {
    font-family: Google Sans;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px;
    letter-spacing: 0.04em;
    text-align: left;
    color: #718096;
    width: 40%;
}

.integrations .crm-block-background-wrapper {
    background: url("/integrations-crm-background.png");
    background-size: 100% 100%;
    height: 1250px;
}

.integrations .crm-block .description {
    font-family: Google Sans;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px;
    letter-spacing: 0.04em;
    text-align: center;
    color: #718096;
    max-width: 40%;
    margin: auto;
    margin-top: 1.5rem;
}

.integrations .crm-block .crm-images-block {
    display: flex;
    flex-wrap: wrap;
    max-width: 80%;
    margin: auto;
    margin-top: 30px;
}

.integrations .crm-block .crm-images-block .image-item {
    flex: 25%;
}

.integrations .image-item .card:hover .client-link {
    display: flex;
}

.integrations .image-item .card {
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;

    background: #fff;

    padding: 20px;

    margin: 10px auto;
    margin-top: 0px;

    height: 160px;
    width: 170px;

    border: 1px solid #d9e7e3;
}

.integrations .image-item .card:hover {
    box-shadow: 0px 28px 68px 0px #00000012;
}

.integrations .image-item .card .client-name {
    font-family: Google Sans;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 23px;
    letter-spacing: 0.02em;
    text-align: center;
    margin-top: 1rem;
}

.integrations .image-item .card .client-link {
    font-family: Google Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;

    line-height: 20px;
    letter-spacing: 0.02em;

    text-align: center;

    color: #3364fa;

    display: none;

    margin-top: 5px;
}

.integrations .crm-block .crm-images-block .image-item .client-logo {
    height: 50px;
    width: 50px;
    object-fit: contain;
}

.integrations .crm-block .crm-images-block .payment-item {
    flex: 33%;
}

.integrations .whatsapp-chatbot .get-started {
    padding: 2rem 0;
}

/* Ecommerce Blog Logo placement */
.integrations .ecommerce-block .icon-wrapper {
    position: relative;
    height: 100%;
    max-width: 1140px;
    margin: auto;
}

.integrations .ecommerce-block .image-item .card {
    background: #f0f3fd;
    transition: all 0.3s ease-in-out;
}

.integrations .ecommerce-block .image-item .card:hover {
    background: #fff;
}

.integrations .ecommerce-block .image-item {
    padding: 0;
    position: absolute;
}

.integrations .ecommerce-block .opencart-logo {
    top: 50px;
    right: 26%;
}

.integrations .ecommerce-block .bikayi-logo {
    top: 125px;
    right: 44%;
}

.integrations .ecommerce-block .shopify-logo {
    left: 41.5%;
    bottom: 138px;
}

.integrations .ecommerce-block .wix-logo {
    bottom: 72px;
    left: 18%;
}

.integrations .ecommerce-block .woocommerce-logo {
    right: 25%;
    bottom: 313px;
}

.integrations .ecommerce-block .storehippo-logo {
    right: 21%;
    bottom: 33%;
}

.integrations .ecommerce-block .pickrr-logo {
    right: 2%;
    top: 5%;
}

.integrations .ecommerce-block .shiprocket-logo {
    right: 4%;
    bottom: 23%;
}

@media (max-width: 426px) {
    .integrations .landing .container .flex {
        flex-flow: column;
        margin: 0 1.5rem;
    }

    .integrations .landing .landing-title {
        margin-top: 0;
    }

    .integrations .landing .cta-buttons {
        margin: 2rem 0 !important;
        margin-left: 0 !important;
    }

    .integrations .integrations-clients .images-block {
        flex-flow: wrap;
    }

    .integrations .ecommerce-block .description {
        width: auto;
        margin: 0 3.5rem;
        text-align: center;
    }

    .integrations .colored-text {
        margin-left: 0;
        display: block;
    }

    .integrations .ecommerce-block .heading {
        text-align: center;
        padding-top: 0;
    }

    .integrations .crm-block .description {
        max-width: 85%;
    }

    .integrations .crm-block-background-wrapper {
        background-size: cover;
        height: auto;
    }

    .whatsapp-chatbot .get-started .title {
        text-align: center;
        margin: auto;
        max-width: 90%;
    }

    .integrations .whatsapp-chatbot .blog {
        padding-top: 50px;
    }

    .integrations .ecommerce-block {
        height: auto;
    }

    /* .integrations .ecommerce-block .images-block {
        display: flex;
        flex-wrap: wrap;

        max-width: 95%;

        margin: auto;
        margin-top: 3rem;
    } */

    .integrations .ecommerce-block .image-item {
        position: unset;
        flex: 50%;
    }
}

/* 15 Integration CMS
/* ---------------------------------------------------------- */

.integrations-cms .landing {
    padding: 3.5rem 0;
    padding-bottom: 0;

    /*background-image: url("/integrations-cms/landing-background.svg");*/
    background-size: cover;
}

.integrations-cms .landing-title {
    font-family: Google Sans;
    font-size: 44px;
    font-style: normal;

    font-weight: 400;

    line-height: 61px;

    letter-spacing: 0.02em;

    margin-bottom: 0.4rem;
}

.integrations-cms .landing-description {
    font-family: Google Sans;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: 50px;
    letter-spacing: 0.02em;
    text-align: left;
    background: linear-gradient(90.11deg, #3364fa 0.09%, #25cf41 99.96%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.integrations-cms .landing .landing-right-banner {
    margin-bottom: 55px;
    width: 500px;
}

.integrations-cms .landing-list {
    font-family: Google Sans;

    font-size: 18px;
    font-style: normal;
    font-weight: 400;

    line-height: 38px;
    letter-spacing: 0.02em;

    text-align: left;
    color: #718096;

    margin-top: 2rem;
}

.integrations-cms .integrations-cta {
    position: relative;
    top: -75px;
}

.integrations-cms .card {
    /*background: #004C3F;*/
    border-radius: 40px;
    box-shadow: 0px 37px 68px 0px #0000000a;
    padding: 2rem;
}

.integrations-cms .card-title {
    font-family: Google Sans;

    font-size: 30px;
    font-style: normal;
    font-weight: 500;

    line-height: 38px;
    letter-spacing: 0.04em;

    text-align: left;

    color: #ffffff;
}

.integrations-cms .integrations-clients .title {
    font-family: Google Sans;

    font-size: 30px;
    font-style: normal;
    font-weight: 500;

    line-height: 38px;
    letter-spacing: 0.02em;
    text-align: center;
    color: #283560;
}

.integrations-cms .images-block {
    margin: 3rem auto;
    display: flex;
    justify-content: space-around;
}

.integrations-cms .images-block img {
    object-fit: contain;
}

.integrations-cms .title {
    font-family: Google Sans;

    font-size: 44px;
    font-style: normal;
    font-weight: 700;

    line-height: 48px;
    letter-spacing: 0em;

    color: #303b6a;
}

.integrations-cms .features-block {
    background: #f0f3fd;
    padding-top: 4rem;
}

.integrations-cms .features-block .title {
    font-size: 30px;
}

.integrations-cms .features-block .person-image {
    height: 320px;
    object-fit: contain;
}

.integrations-cms .features-block .client-logos img {
    width: 150px;
    object-fit: contain;
}

.integrations-cms .features-block .client-logos img:nth-of-type(2n) {
    width: auto;
    margin: 0 1.5rem;
}

.integrations-cms .features-block .card {
    background: rgba(255, 255, 255, 0.3);
    width: 275px;
    border-radius: 0px;
}

.integrations-cms .features-block .display-cards img {
    height: 70px;
    object-fit: contain;
    margin-bottom: 1rem;
}

.integrations-cms .features-block .card-text {
    font-family: Google Sans;

    font-size: 23px;
    font-style: normal;
    font-weight: 700;

    line-height: 37px;

    text-align: center;

    color: #303b6a;

    margin-top: 0.3rem;
}

.integrations-cms .table-view {
    margin: 5rem 0;
}

.integrations-cms .table-view img {
    height: 375px;
    object-fit: contain;
}

.integrations-cms .table-view .heading {
    font-family: Google Sans;
    font-size: 30px;
    font-style: normal;
    font-weight: 700;
    line-height: 49px;
    letter-spacing: 0.02em;
    text-align: center;
    color: #303b6a;

    max-width: 80%;
    margin: auto;
    margin-bottom: 1rem;
}

.integrations-cms .integrations-slide {
    background: #f0f3fd;
    padding: 5rem 0;
}

.integrations-cms .integrations-slide .slides-block {
    margin-top: 4rem;
}

.integrations-cms .integrations-slide .sub-title {
    font-family: Google Sans;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 23px;
    letter-spacing: 0.06em;
    text-align: center;

    color: #00be99;
}

.integrations-cms .integrations-slide .title {
    font-family: Google Sans;
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: 46px;
    letter-spacing: 0.02em;
    text-align: center;
    color: #283560;
    text-align: center;
}

.integrations-cms .integrations-slide .title-colored {
    background: linear-gradient(90.11deg, #3364fa 0.09%, #25cf41 99.96%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.integrations-cms .integrations-slide .slides-block .images {
    max-width: 80%;
    overflow: hidden;
}

.integrations-cms .integrations-slide .slides-block button {
    outline: none;
    outline-color: #fff;
    border: none;
}

.integrations-cms .integrations-slide .slides-block {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.integrations-cms .get-started {
    padding: 2rem 0;
}

.integrations-cms .table-view .target-image {
    position: relative;
    left: -20px;
}

.integrations-cms .pink-circle {
    position: absolute;

    left: -9px;
    bottom: -16px;

    width: 90px;
    height: 90px;

    background: rgba(258, 113, 110);
    opacity: 0.4;
    filter: blur(40px);
}

.integrations-cms .sky-blue-circle {
    position: absolute;

    left: -19px;
    bottom: -14px;

    width: 150px;
    height: 150px;

    background: #47ebe1;
    opacity: 0.3;
    filter: blur(50px);
}

.integrations-cms .dark-blue-circle {
    position: absolute;

    top: 0px;
    right: -43px;

    width: 150px;
    height: 150px;

    background: #3364fa;
    opacity: 0.3;
    filter: blur(58px);
}

/* 
.integrations-cms .get-started .title {
    font-size: 32px;
} */

@media (max-width: 426px) {
    .integrations-cms .landing-title {
        font-size: 37px;
    }

    .integrations-cms .landing .flex {
        flex-flow: column;
        max-width: 90%;
        margin: auto;
    }

    .integrations-cms .landing .landing-right-banner {
        margin-top: 5rem;
    }

    .integrations-cms .integrations-cta {
        top: -55px;
        max-width: 95%;
        margin: auto;
    }

    .integrations-cms .integrations-cta .container > .flex {
        flex-flow: column;
    }

    .integrations-cms .integrations-cta button {
        margin-top: 2rem;
    }

    .integrations-cms .images-block {
        flex-wrap: wrap;
    }

    .integrations-cms .images-block img {
        /* flex: 40%; */
        margin: 5% 5%;
    }

    .integrations-cms .features-block .container > .flex {
        flex-flow: column;
        max-width: 95%;
        margin: auto;
    }

    .integrations-cms .features-block .title {
        text-align: center;
    }

    .integrations-cms .features-block .client-logos {
        justify-content: space-around;
    }

    .integrations-cms .features-block .display-cards {
        flex-flow: column;
    }

    .integrations-cms .features-block .display-cards .card {
        width: 90%;
        margin: 2rem auto;
    }

    .integrations-cms .table-view .container > .flex {
        flex-flow: column;
    }

    .inte5grations-cms .table-view .target-image {
        margin-right: 0;
        left: 0;
    }

    .integrations-cms .table-view button {
        margin-bottom: 3rem;
    }

    .integrations-cms .table-view img {
        max-width: 90%;
        margin: auto;
    }

    .integrations .crm-block .crm-images-block {
        display: none;
    }
}

/* Real Estate */
.real-estate .colored-card-text {
    font-weight: 700;
    color: var(--sky-blue);
}

.real-estate-scale .app-card {
    height: 108px;
    width: 108px;
    margin: 0.5rem auto;
    padding: 1.5rem;
    border-radius: 20px;
}

.real-estate-scale .card-logo {
    width: 70px;
    height: 70px;
    object-fit: contain;
    margin: auto;
}

.real-estate .card-text-wrapper {
    width: 270px;
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: flex-start;
}

.real-estate-scale .card-text {
    font-family: Google Sans;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
    letter-spacing: 0.02em;
    color: var(--black);
    text-align: center;
    margin-top: 1rem;
}

/* .real-estate .landing {
    padding-top: 6rem;
    background: url("/real_estate/real_estate_banner.png");
} */

.real-estate-power {
    padding: 5rem 0;
    background: var(--purple-2);
}

.travel .landing {
    padding-top: 3rem;
}

.travel .landing-right-banner {
    max-width: 155%;
    object-fit: contain;
    position: relative;
    right: 65px;
    top: 33px;
}

.real-estate .cta-content-wrapper {
    /*padding: 1rem 0;*/
    /*background: #E5E5E5;*/
}

/* Travel */
.power-travel-card-wrapper {
    /*background: #E5E5E5;*/
}

.power-travel-card .app-card {
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;

    padding: 2rem 3rem;

    background: #f9fafc;

    box-shadow: none;
    border: 1px solid #ebf0f6;

    width: 350px;

    border-radius: 12px;
}

.power-travel-card .app-card .cta-image {
    width: 200px;
    height: 200px;
    object-fit: contain;
    margin-bottom: 1rem;
}

.power-travel-card .colored-text {
    font-family: Google Sans;
    font-size: 46px;
    font-style: normal;
    font-weight: 700;
    line-height: 50px;
    letter-spacing: 0.02em;
    text-align: left;
    margin-bottom: 0.5rem;
}

.power-travel-card .description {
    font-family: Google Sans;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
    letter-spacing: 0.02em;
    text-align: center;
    color: var(--light-grey-2);
}

.travel .personalised-travel {
    padding-top: 5rem;
}

.travel .cta-content-wrapper {
    padding: 1rem 0;
    background: #f9fafc;
}

.personalised-travel .card-container {
    margin-top: 3rem;
    height: 555px;
    /*background: url("/travel/test_background.png");*/
}

.personalised-travel .card-container:before {
    content: " ";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 555px;
    opacity: 0.22;
    z-index: -1;
    /* background: url("/travel/personalised_travel/background.png"); */
    background-size: contain;
    background-repeat: no-repeat;
}

.personalised-travel .app-card {
    border-radius: 23px;
    width: 445px;
    padding: 1rem;
}

.personalised-travel .app-card .flag-icon {
    position: absolute;
    top: -22px;
    width: 17px;
    object-fit: contain;
}

.personalised-travel .app-card .icon {
    width: 60px;
    object-fit: contain;
}

.personalised-travel .app-card .content {
    margin-left: 1rem;
    font-family: Google Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0.04em;
    text-align: left;
    color: var(--black);
}

.personalised-travel .card-purple {
    background: #e1e4ff;
    position: absolute;
    top: 0;
    right: 194px;
}

.personalised-travel .card-pink {
    background: #ffdbdbcc;
    width: 282px;
    position: absolute;
    top: 66px;
    left: 176px;
}

.personalised-travel .card-yellow-dark {
    background: #fffbd6cc;
    width: 300px;
    position: absolute;
    top: 210px;
    left: 80px;
}

.personalised-travel .card-blue {
    background: #d3fcffcc;
    width: 325px;
    position: absolute;
    right: 356px;
    top: 180px;
}

.personalised-travel .card-yellow-light {
    background: #e2ffdbcc;
    position: absolute;
    width: 370px;
    bottom: 145px;
    left: 240px;
}

.personalised-travel .card-blue-dark {
    background: #d6ebffcc;
    width: 345px;
    position: absolute;
    bottom: 145px;
    right: 113px;
}

.personalised-travel .card-purple-dark {
    background: #f4d3ffcc;
    position: absolute;
    right: 64px;
    top: 114px;
    width: 265px;
}

@media (max-width: 426px) {
    .real-estate,
    .travel {
        padding-top: 0;
    }

    .real-estate .landing .container > .flex {
        flex-flow: column;
    }

    .real-estate-scale .container > .flex {
        flex-flow: column;
    }

    .real-estate-scale .card-text-wrapper {
        margin: auto;
    }

    .travel .landing .container > .flex,
    .power-travel-card-wrapper .container > .flex {
        flex-flow: column;
    }

    .power-travel-card {
        margin: 2rem 0;
    }

    .travel .landing-right-banner {
        position: relative;
        width: 100%;
        max-width: 100%;
        right: 0;
        bottom: 0;
    }

    .personalised-travel .app-card {
        position: relative;
        top: 0 !important;
        right: 0 !important;
        bottom: 0 !important;
        left: 0 !important;
        width: 100%;
        margin: 3rem 0;
    }

    .personalised-travel .card-container {
        height: auto;
    }
}

/* 16 Integration - Ecommerce
/* ---------------------------------------------------------- */

.ecommerce-integrations button {
    font-family: Google Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
}

.ecommerce-integrations .landing {
    background: #e9f3ee;
}

.ecommerce-integrations .landing-banner {
    position: absolute;
    right: 10px;
    top: 109px;
}

.ecommerce-integrations .landing-text-block {
    max-width: 100%;
}

.ecommerce-integrations .title {
    font-family: Google Sans;

    font-size: 34px;
    font-style: normal;
    font-weight: 700;

    line-height: 52px;
    letter-spacing: 0em;
}

.ecommerce-integrations .description {
    font-family: Google Sans;

    font-size: 16px;
    font-style: normal;
    font-weight: 400;

    line-height: 28px;
    letter-spacing: 0.02em;

    color: #718096;
}

.ecommerce-integrations .integrations-clients .images-block img {
    height: 80px;
    width: 80px;
}

.ecommerce-integrations .show-case .right-banner {
    max-width: 600px;
    object-fit: contain;
}

.ecommerce-integrations .personalize {
    background: #edf1f7;
}

.ecommerce-integrations .personalize-card {
    width: 180px;
    height: 170px;

    display: flex;
    flex-flow: column;
    justify-content: space-evenly;
    align-items: center;
    padding: 1rem;

    background: white;

    border-radius: 25px;
    border: 1px solid #d4d4d6;
    border-radius: 27px;
}

.ecommerce-integrations .personalize-card img {
    height: 40px;
    width: 40px;
    margin-bottom: 0.5rem;
    object-fit: contain;
}

.ecommerce-integrations .personalize-card .text {
    font-family: Google Sans;

    font-size: 16px;
    font-style: normal;
    font-weight: 400;

    line-height: 24px;
    letter-spacing: 0.04em;

    text-align: center;
    color: #000000;
}

.ecommerce-integrations .get-started .title {
    font-size: 32px;
}

.ecommerce-integrations .templates {
    padding: 3rem 0;
}

.ecommerce-integrations .templates .cta-text-block {
    flex-basis: 40%;
    width: 40%;
}

.ecommerce-integrations .templates .video-block {
    flex-basis: 60%;
    width: 60%;
}

.ecommerce-integrations .templates .video-block iframe {
    width: 100%;
    height: 100%;
    padding: 3rem;
    padding-right: 1rem;
}

.ecommerce-integrations .broadcast {
    padding-bottom: 7rem;
}

.ecommerce-integrations .broadcast .title {
    font-family: Google Sans;

    font-size: 38px;
    font-style: normal;
    font-weight: 500;

    line-height: 54px;
    letter-spacing: 0.02em;
    color: #333333;
}

.ecommerce-integrations .broadcast img {
    max-width: 525px;
    object-fit: contain;
}

.ecommerce-integrations .submit-btn:hover {
    box-shadow: 0px 12px 24px 1px rgba(51, 100, 250, 0.21);
}

.ecommerce-integrations .submit-btn:hover .submit-btn-icon {
    transform: translateX(0.25rem);
}

.client-love {
    background: url("https://assets.gallabox.com/gb-home/integrations/ecommerce/client-love-background.png");
    background-size: cover;
    padding: 4rem 0;
}

.client-love .sub-title {
    font-family: Google Sans;

    font-size: 18px;
    font-style: normal;
    font-weight: 700;

    line-height: 23px;
    letter-spacing: 0.06em;

    text-align: center;

    color: #00be99;
}

.client-love .title {
    font-family: Google Sans;

    font-size: 36px;
    font-style: normal;
    font-weight: 700;

    line-height: 46px;
    letter-spacing: 0.02em;

    text-align: center;

    color: #ffffff;

    margin-top: 0.5rem;
}

.client-love .images {
    max-width: 55%;
    margin: auto;
    margin-top: 2rem;
}

.client-love .dots {
    display: flex;
    padding: 10px 0;
    justify-content: center;
}

.client-love .dot {
    border: none;
    width: 10px;
    height: 10px;
    background: transparent;
    border-radius: 50%;
    margin: 0 5px;
    padding: 5px;
    cursor: pointer;
    border: 1px solid #ffffff;
}

.client-love .dot:focus {
    outline: none;
}

.client-love .dot.active {
    background: #ffffff;
}

@media (max-width: 426px) {
    .ecommerce-integrations .landing-text-block {
        max-width: 100%;
    }

    .ecommerce-integrations .landing-banner {
        position: relative;
        top: 0;
        right: 0;
    }

    .ecommerce-integrations .show-case {
        padding: 0 1.5rem;
    }

    .ecommerce-integrations .show-case .container > .flex {
        flex-flow: column;
    }

    .ecommerce-integrations .show-case .container button {
        width: 100%;
    }

    .ecommerce-integrations .show-case .colored-text {
        display: inline;
    }

    .ecommerce-integrations .personalize {
        padding: 0 1.5rem;
    }

    .ecommerce-integrations .personalize .container > .flex {
        justify-content: center;
        flex-wrap: wrap;
    }

    .ecommerce-integrations .personalize .personalize-card {
        margin: 0.5rem;
    }

    .ecommerce-integrations .templates,
    .ecommerce-integrations .broadcast {
        padding: 0 1.5rem;
    }

    .ecommerce-integrations .templates .container > .flex,
    .ecommerce-integrations .broadcast .container > .flex {
        flex-flow: column;
    }

    .ecommerce-integrations .templates .cta-text-block {
        width: 100%;
    }

    .ecommerce-integrations .templates .video-block {
        width: 100%;
    }

    .ecommerce-integrations .templates .video-block iframe {
        padding: 0;
        margin-top: 3rem;
        height: 300px;
    }

    .ecommerce-integrations .broadcast img {
        margin-top: 2rem;
    }

    .client-love .images {
        max-width: 90%;
    }
}

.app-home .landing .top-text {
    font-family: Google Sans;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 23px;
    letter-spacing: 0.06em;
    color: var(--green-2);
}

.app-home .landing .landing-title {
    /*font-family: Gotham;*/
    margin: 1.3rem 0;
    font-size: 94px;
    font-style: normal;
    font-weight: 700;
    line-height: 85px;
    letter-spacing: 0.02em;
}

.app-home .square {
    display: inline-block;
    width: 15px;
    height: 15px;
    margin-left: 0.5rem;
    background: var(--dark-blue-2);
}

.app-home .landing .landing-description {
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: 34px;
    letter-spacing: 0.05em;
    color: var(--black);
}

.app-home .client-logos {
    margin-top: 5rem;
}

.client-logos .title {
    font-family: Google Sans;
    font-size: 28px;
    font-style: normal;
    font-weight: 400;
    line-height: 36px;
    letter-spacing: 0.02em;
    text-align: center;
    color: #000;
}

@media (max-width: 720px) {
    .client-logos .title {
        font-size: 18px;
        line-height: 32px;
    }
}

.app-home .client-logos .logos-wrapper,
.client-logos .logos-wrapper {
    display: flex;
    /* flex-wrap: wrap; */
    /* justify-content: space-between; */

    max-width: 100%;
    margin: auto;
    margin-top: 3rem;
    gap: 2em;
    margin-bottom: 1em;
}

.app-home .client-logos .logo {
    /* border: 1px solid #e0e7f2; */
    /* padding: 0.8rem; */
    border-radius: 8px;
    margin-right: 0rem;
    /* margin-bottom: 2rem;
    width: 150px;
    height: 80px; */
    object-fit: contain;
}

.app-home .client-logos .logo-container {
    flex: 12%;

    display: flex;
    justify-content: center;
    align-items: center;
}

.app-home .client-logos .logos-wrapper.second-row-logos,
.client-logos .logos-wrapper.second-row-logos {
    max-width: 95%;
}

.app-home .client-logos .logos-wrapper.second-row-logos .logo-container,
.client-logos .logos-wrapper.second-row-logos .logo-container {
    flex: 15%;
}

.app-home .client-logos .logos-wrapper.second-row-logos .logo-container img,
.client-logos .logos-wrapper.second-row-logos .logo-container img {
    width: 150px;
    padding: 1rem;
}

/* Adjusting home logos to fit bigger */
.app-home .client-logos .logo-container:nth-of-type(9),
.app-home .client-logos .logo-container:nth-of-type(10),
.app-home .client-logos .logo-container:nth-of-type(11),
.app-home .client-logos .logo-container:nth-of-type(12),
.app-home .client-logos .logo-container:nth-of-type(13),
.app-home .client-logos .logo-container:nth-of-type(14) {
    flex: 15%;
}

.app-home .client-logos .logo-container:nth-of-type(9) img,
.app-home .client-logos .logo-container:nth-of-type(12) img,
.app-home .client-logos .logo-container:nth-of-type(13) img {
    width: 150px;
}

.app-home .client-logos .logo-container:nth-of-type(10) img,
.app-home .client-logos .logo-container:nth-of-type(11) img {
    width: 150px;
}

.logo-mobile {
    display: none !important;
    position: relative;
}

.app-home .easy-integrations {
    background: #ecf0f3;
    padding: 5rem 0;
    padding-top: 10rem;
}

.app-home .easy-integrations .title {
    font-family: Google Sans;
    font-size: 36px;
    font-style: normal;
    font-weight: 500;
    line-height: 54px;
    letter-spacing: 0.02em;
    color: #303b6a;
    margin: 0;
    margin-top: 1rem;
}

.app-home .image-content-block .heading {
    color: #182242;
}

.logo-mobile {
    display: none !important;
}

.app-home .easy-integrations {
    background: #ecf0f3;
    padding: 5rem 0;
    padding-top: 10rem;
}

.app-home .easy-integrations .title {
    font-family: Google Sans;
    font-size: 36px;
    font-style: normal;
    font-weight: 500;
    line-height: 54px;
    letter-spacing: 0.02em;
    text-align: left;
    color: #303b6a;
    margin: 0;
    margin-top: 1rem;
}

.app-home .image-content-block .heading {
    color: #182242;
}

@media (max-width: 426px) {
    .app-home .landing .container > .flex {
        flex-flow: column;
    }

    .app-home .landing .container .flex .w-1\/2 {
        width: 100%;
        text-align: center;
    }

    .app-home .landing .top-text {
        text-align: center;
        font-size: 17px;
        margin-bottom: 1rem;
    }

    .app-home .landing .landing-title {
        /*font-family: Gotham;*/
        font-size: 66px;
        font-weight: 800;
        line-height: 80px;
        text-align: center;
        margin: 0.3rem 0;
    }

    .app-home .landing .landing-description {
        margin-top: 2rem;
    }

    .app-home.pt-20 {
        padding-top: 0;
    }

    .logo-desktop {
        display: none !important;
    }

    .logo-mobile {
        display: flex !important;
        margin-top: 1rem;
    }

    .app-home .testimonial-card {
        padding-top: 2rem;
    }

    .app-home .testimonial-card .role {
        height: 18px;
    }
}

.whatsapp-broadcast {
    background: #ecf0f3;
}

.whatsapp-broadcast .video {
    background: #e6ebee;
}

.whatsapp-broadcast .landing {
    padding-top: 5rem;
}

.whatsapp-broadcast .landing .title {
    font-size: 55px;
    font-style: normal;
    font-weight: 700;
    line-height: 70px;
    letter-spacing: 0.02em;
    text-align: left;
}

.whatsapp-broadcast .landing .app-sub-title-text {
    font-size: 22px;
}

.whatsapp-broadcast .landing-right-banner {
    max-width: 600px;
    object-fit: contain;
    position: absolute;
    top: 38px;
}

.whatsapp-broadcast .app-colored-text {
    font-size: 36px;
}

.whatsapp-broadcast .heading {
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: 54px;
    letter-spacing: 0em;
}

.whatsapp-broadcast .sub-heading {
    font-family: Google Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
    letter-spacing: 0.02em;
    color: var(--light-grey-2);
    margin-bottom: 2rem;
}

.whatsapp-broadcast .video {
    padding-top: 1.5rem;
    margin-top: 4rem;
}

.video-cta {
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 14px;
    letter-spacing: 0.02em;
    text-align: left;
    color: #4570f4;
    margin-left: 0.4rem;
}

.video-cta:hover {
    text-decoration: underline;
}

.whatsapp-broadcast .segment .heading {
    font-size: 34px;
}

.whatsapp-broadcast .segment .sub-heading {
    margin-bottom: 1rem;
    font-family: Google Sans;
    font-size: 22px;
    font-style: normal;
    font-weight: 500;
    line-height: 30px;
    letter-spacing: 0.02em;
    text-align: left;
    color: #718096;
}

.whatsapp-broadcast .client-testimonials {
    margin-top: 0;
}

.whatsapp-broadcast .card-block-wrapper {
    max-width: 90%;
    margin: auto;
}

.whatsapp-broadcast .home-icon {
    display: inline;
    height: 50px;
    width: 50px;
    object-fit: contain;
}

.blue-circle {
    position: absolute;
    width: 200px;
    height: 200px;
    left: -88px;
    top: 566px;

    background: #00ffef;
    opacity: 1;
    filter: blur(243px);
}

.dark-blue-circle {
    position: absolute;
    width: 250px;
    height: 250px;
    left: 1395px;
    top: 539px;

    background: #3364fa;
    opacity: 1;
    filter: blur(243px);
}

@media (max-width: 426px) {
    .whatsapp-broadcast.pt-20 {
        padding-top: 0;
    }

    .custom-width {
        width: 100%;
        max-width: 140px;
    }

    .whatsapp-broadcast .landing .w-1\/2,
    .whatsapp-broadcast .cta-block .w-1\/2,
    .whatsapp-broadcast .segment .w-1\/2 {
        width: 100%;
    }

    .app-home .landing .landing-title {
        /*font-family: Gotham;*/
        font-size: 66px;
        font-weight: 800;
        line-height: 80px;
        text-align: center;
        margin: 0.3rem 0;
        position: relative;
    }

    .app-home .square {
        position: absolute;
        bottom: 20px;
    }

    .app-home .landing .landing-description {
        margin-top: 2rem;
    }

    .client-testimonials .app-card {
        max-width: 100%;
    }

    .client-testimonials .left-arrow {
        top: 350px;
        left: -37px;
    }

    .client-testimonials .right-arrow {
        top: 350px;
        right: -32px;
    }

    .whatsapp-broadcast .landing .flex,
    .whatsapp-broadcast .cta-block .flex,
    .whatsapp-broadcast .segment .flex {
        flex-flow: column;
    }

    .whatsapp-broadcast .segment .segment-item {
        flex-flow: row;
    }

    .whatsapp-broadcast .card-block-wrapper {
        max-width: 100%;
        align-items: center;
        flex-wrap: wrap;
    }

    .whatsapp-broadcast .video .w-2\/3 {
        width: 100%;
    }

    .broadcast-cta .flex {
        flex-flow: column;
    }

    .whatsapp-broadcast .landing .whatsapp-text {
        display: flex;
        align-items: center;
    }

    .whatsapp-broadcast .landing br {
        display: none;
    }

    .whatsapp-broadcast .cta-block.py-20 {
        padding-bottom: 0;
    }

    .whatsapp-broadcast .cta-block img {
        margin-top: 3rem;
    }

    .whatsapp-broadcast .video .video-banner {
        margin-top: 3rem;
    }

    .whatsapp-broadcast .segment .segment-banner {
        margin-top: 3rem;
    }

    .whatsapp-broadcast .landing-right-banner {
        width: 100%;
        object-fit: contain;
        position: unset;
    }
}

.bfsi .landing .title {
    font-size: 60px;
    font-style: normal;
    font-weight: 700;
    line-height: 80px;
    letter-spacing: 0.02em;
    text-align: left;
}

.bfsi .landing {
    background: var(--light-blue-2);
    padding: 5rem 0;
}

.bfsi .landing-right-banner {
    object-fit: contain;
    max-width: 600px;
    width: 100%;
}

.bfsi .page-heading {
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: 54px;
    letter-spacing: 0em;
    color: #283560;
}

.bfsi .portfolio-numbers .page-heading {
    font-size: 32px;
}

.bfsi .number-card {
    width: 200px;

    background: linear-gradient(180deg, rgba(255, 255, 255, 0.87) 0%, rgba(255, 255, 255, 0.33) 100%);
    border: 2px solid #d9e3f2;
    backdrop-filter: blur(4.5px);
    border-radius: 21px;

    margin-left: 2rem;
}

.bfsi .number-card:nth-of-type(1) {
    margin-left: 0;
}

.bfsi .number-card .number {
    font-family: Google Sans;
    font-size: 46px;
    font-style: normal;
    font-weight: 700;
    line-height: 50px;
    letter-spacing: 0.02em;
    text-align: left;
}

.bfsi .number-card {
    padding: 2rem 1rem;
    height: 188px;
}

.bfsi .number-card .text {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.02em;
    text-align: left;
    color: #000000;
}

.bfsi .app-colored-text {
    font-size: 36px;
}

.bfsi .card-block-wrapper {
    max-width: 75%;
    margin: auto;
    margin-top: 2rem;
}

.bfsi .broadcast-cta {
    background: #3364fa;
}

.bfsi .image-content-block .heading {
    font-size: 38px;
    font-style: normal;
    font-weight: 700;
    line-height: 54px;
    letter-spacing: 0.02em;
    color: #333333 !important;
}

.bfsi .image-content-block .description {
    font-family: Google Sans;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
    letter-spacing: 0.04em;
    color: #444444 !important;
}

.bfsi .image-content-block .reverse {
    background: #ecf0f3;
}

.bfsi .image-content-block .reverse .flex {
    flex-flow: row-reverse;
}

.bfsi .image-content-block .reverse .description {
    max-width: 80%;
}

.bfsi .image-content-wrapper {
    padding: 4rem 0;
}

.bfsi .client-testimonials {
    margin-top: 0;
}

@media (max-width: 426px) {
    .bfsi.pt-20 {
        padding-top: 0;
    }

    .bfsi .landing .container > .flex,
    .bfsi .portfolio-numbers .container > .flex,
    .bfsi .portfolio-numbers .container > .flex > .flex,
    .bfsi .image-content-wrapper .flex {
        flex-flow: column !important;
    }

    .logo-mobile {
        display: block !important;
        margin-top: 3rem;
    }

    .bfsi .number-card {
        margin: 0;
        margin-bottom: 2rem;
    }

    .bfsi .landing .container .w-1\/2,
    .bfsi .image-content-wrapper .w-1\/2,
    .bfsi .image-content-wrapper .w-2\/3,
    .bfsi .portfolio-numbers .w-2\/5,
    .bfsi .image-content-block .reverse .description {
        width: 100%;
    }

    .bfsi .app-clients-block {
        top: -18px !important;
    }

    .bfsi .card-block-wrapper {
        max-width: 100%;
        flex-wrap: wrap;
    }
}

.app-kuwait .pricing,
.app-kuwait .landing,
.app-kuwait .client-logos {
    background: #ecf0f3;
}

.app-kuwait .landing {
    padding-top: 5rem;
}

.app-kuwait .client-logos {
    margin-top: 0;
    padding: 5rem 0;
}

.app-kuwait .client-testimonials {
    margin-top: 0;
}

.app-kuwait .client-logos .logo-container {
    flex: 13%;
}

.app-kuwait .image-content-block .image-content-wrapper .heading {
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: 57px;
    letter-spacing: 0.02em;
    color: #000000;
}

.app-kuwait .image-content-block .image-content-wrapper .description {
    font-size: 19px;
    font-style: normal;
    font-weight: 400;
    line-height: 34px;
    letter-spacing: 0.04em;
    color: #5d6b7e;
}

.app-kuwait .easy-integrations {
    background: #ffffff;
    padding: 5rem 0;
    padding-top: 10rem;
}

.app-kuwait .logo-container .logo {
    background: #ffffff;
}

.app-kuwait .easy-integrations .title {
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: 57px;
    letter-spacing: 0.02em;
    color: var(--black);
}

.app-kuwait .easy-integrations .description {
    font-family: Google Sans;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 34px;
    letter-spacing: 0.04em;
    color: #5d6b7e;
}

.app-kuwait .image-content-wrapper .text-block {
    margin-left: 5rem;
}

.app-kuwait .image-content-wrapper.reverse .text-block {
    margin-left: 0;
    margin-right: 5rem;
}

.app-kuwait .pricing {
    background: #ecf0f3;
    padding-bottom: 5rem;
}

.home-easy-integrations {
    padding: 5rem 0;
    background: #ecf0f3;
}

.home-easy-integrations .title {
    font-size: 40px;
    font-weight: 700;
    line-height: 57px;
    letter-spacing: 0.02em;
    text-align: left;
    color: #182242;
    max-width: 90%;
}

.home-easy-integrations .description {
    font-size: 20px;
    font-weight: 400;
    line-height: 34px;
    letter-spacing: 0.04em;
    text-align: left;
    color: #5d6b7e;
    max-width: 60%;
    margin-bottom: 2rem;
}

.integration-slide-images {
    min-width: 700px;
}

@media (max-width: 426px) {
    .app-kuwait .image-content-wrapper .container > .flex {
        flex-flow: column;
    }

    .app-kuwait .image-content-wrapper .text-block,
    .app-kuwait .image-content-wrapper.reverse .text-block {
        margin-left: 0;
        margin-right: 0;
    }

    .app-kuwait .pricing-footer {
        padding: 1rem 1.5rem;
    }

    .app-home .home-easy-integrations .container > .flex {
        flex-flow: column;
    }

    .app-home .home-easy-integrations .flex-basis-70 {
        flex-basis: 100%;
    }

    .app-home .home-easy-integrations .title,
    .app-home .home-easy-integrations .description {
        max-width: 100%;
    }

    .home-easy-integrations {
        padding-top: 0rem;
        padding-bottom: 2rem;
    }

    .integration-slide-images {
        min-width: 400px;
    }
}

/* Whatsapp Chatbot Landing
/* ---------------------------------------------------------- */

.whatsapp-chatbot-landing {
    padding-top: 5rem;
}

.whatsapp-chatbot-landing .landing {
    display: flex;
    flex-flow: column;

    align-items: center;
    justify-content: center;

    padding-top: 3rem;

    background: linear-gradient(180deg, #e7f5ff 0%, rgba(231, 245, 255, 0) 100%);
}

.whatsapp-chatbot-landing .landing .title {
    font-size: 44px;
    font-weight: 700;
    line-height: 60px;
    letter-spacing: 0.02em;
    color: #182242;
}

.whatsapp-chatbot-landing .landing .description {
    color: var(--light-blue);
}

.whatsapp-chatbot-landing .landing .landing-images-container {
    display: flex;
}

.whatsapp-chatbot-landing .landing .landing-images-container img:nth-of-type(1) {
    margin-right: 5rem;
}

.whatsapp-chatbot-landing .landing .whatsapp-icon {
    object-fit: contain;
    margin-right: 0.5rem;
}

.whatsapp-chatbot-landing .client-logos .logo-container {
    flex: 14%;
}

.whatsapp-chatbot-landing .app-cta-content-with-image {
    margin: 3rem auto;
}

.whatsapp-chatbot-landing .app-cta-content-with-image > .container {
    margin: 4rem auto;
}

.whatsapp-chatbot-landing .benefits-card-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    max-width: 89%;
    margin: auto;
}

.whatsapp-chatbot-landing .benefits-chatbot {
    background: #ecf0f3;
    padding: 5rem 0;
}

.whatsapp-chatbot-landing .benefits-card {
    max-width: 310px;

    border-radius: 24px;

    margin-bottom: 2rem;

    background: #f5f5fa;
    box-shadow: -1px -1px 2px 0px #ffffff inset, 1px 1px 2px 0px #aaaacc80 inset;
}

.whatsapp-chatbot-landing .benefits-card .card-header {
    display: flex;
    align-items: center;

    margin-bottom: 1rem;
}

.whatsapp-chatbot-landing .benefits-card .profile-image {
    border: 2px solid;
    border-image-source: linear-gradient(135deg, rgba(0, 0, 0, 0.05) 0%, rgba(255, 255, 255, 0.05) 100%);
    border-radius: 14px;

    padding: 0.5rem;

    box-shadow: 13px 10px 9.981898307800293px 0px #aeaec04d, -4.9909491539001465px -4.9909491539001465px 9.981898307800293px 0px #ffffff,
        1.9963797330856323px 1.9963797330856323px 3.9927594661712646px 0px #ffffff inset, 0px 1px 3.9927594661712646px 0px #0000001a inset;
}

.whatsapp-chatbot-landing .benefits-card .card-header .card-title {
    font-size: 24px;
    font-weight: 700;
    line-height: 31px;
    letter-spacing: 0.02em;
    text-align: left;

    color: #2d3748;

    margin-left: 1rem;
}

.whatsapp-chatbot-landing .benefits-card .card-description {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.02em;
    text-align: left;

    color: #718096;
}

.whatsapp-chatbot-landing .easy-integrations {
    background: #ecf0f3;
    padding-top: 10rem;
    padding-bottom: 5rem;
}

.whatsapp-chatbot-landing .easy-integrations .title {
    font-size: 40px;
    font-weight: 700;
    line-height: 57px;
    letter-spacing: 0.02em;
    color: #182242;
    margin-top: 0;
}

.whatsapp-chatbot-landing .easy-integrations .description {
    font-size: 20px;
    font-weight: 400;
    line-height: 34px;
    letter-spacing: 0.04em;
    padding-top: 0;
}

.whatsapp-chatbot-landing .app-contact-form .get-started {
    margin-top: 0;
}

.whatsapp-chatbot-landing .image-content-block .app-cta-content-with-image:first-child {
    margin-top: 0;
}

.whatsapp-chatbot-landing .image-content-block .app-cta-content-with-image:last-child {
    margin-bottom: 0;
}

.whatsapp-chatbot-landing .app-cta-content-with-image .content-block .title {
    font-size: 40px;
    font-weight: 700;
    line-height: 57px;
    letter-spacing: 0.02em;
    text-align: left;

    color: #182242;
}

.whatsapp-chatbot-landing .app-cta-content-with-image .content-block .description {
    font-size: 20px;
    font-weight: 400;
    line-height: 34px;
    letter-spacing: 0.04em;
    text-align: left;

    margin-bottom: 0.5em;
}

@media (max-width: 426px) {
    .whatsapp-chatbot-landing {
        padding-top: 0;
    }

    .whatsapp-chatbot-landing img {
        object-fit: contain;
    }

    .whatsapp-chatbot-landing .landing {
        padding: 0 2rem;
        padding-top: 3rem;
    }

    .whatsapp-chatbot-landing .landing .landing-images-container img:nth-of-type(1) {
        margin-right: 0;
        margin-bottom: 2rem;
    }

    .whatsapp-chatbot-landing .landing .landing-images-container {
        flex-flow: column;
        align-items: center;
        justify-content: center;
    }

    .whatsapp-chatbot-landing .landing .landing-images-container img {
        max-width: 300px;
        object-fit: contain;
    }

    .app-cta-content-with-image,
    .app-cta-content-with-image > .container {
        flex-flow: column;
    }
}

/* ---------------------- */
/* ---case-study-page---- */
/* ---------------------- */

.header-text {
    font-family: "Google Sans";
    font-style: normal;
    font-weight: 700;
    font-size: 50px;
    line-height: 64px;
    letter-spacing: 0.02em;
    text-shadow: 2px 4px 8px rgba(0, 0, 0, 0.25);
}

.achievementcard {
    box-sizing: border-box;
    width: 360px;
    height: 200px;
    border-radius: 10px;
    font-family: "Google Sans";
    font-style: normal;
}

.achievementcard .title {
    font-weight: 500;
    font-size: 18px;
    line-height: 34px;
    letter-spacing: 0.05em;
    opacity: 0.9;
}

.achievementcard .count {
    font-weight: 700;
    font-size: 35px;
    line-height: 44px;
    letter-spacing: 0.02em;
}

.achievementcard .by {
    font-weight: 400;
    font-size: 18px;
    line-height: 25px;
    letter-spacing: 0.05em;
    opacity: 0.9;
}

.aside {
    gap: 10px;
    width: 278px;
    height: fit-content;
    background: #edf1f7;
    border: 3px solid #d1dcee;
    box-shadow: 0px 4px 20px rgba(199, 214, 236, 0.5);
    font-style: normal;
    border-radius: 10px;
    font-family: "Google Sans";
    line-height: 25px;
    color: #303b6a;
    letter-spacing: 0.02em;
}

.aside .title {
    font-weight: 700;
    font-size: 20px;
    letter-spacing: 0.02em;
    margin: 0;
    margin-top: 0.5rem;
}

.aside .description {
    font-weight: 400;
    font-size: 15px;
    color: #303b6a;
}

.aside-new {
    display: flex;
    flex-direction: column;
    gap: 10px;
    border: 1px solid #d1dcee;
    border-radius: 10px;
}

.aside-new-item {
    border-bottom: 1px solid #d1dcee;
    padding-bottom: 10px;
}

.aside-new-item:last-child {
    border-bottom: none;
}

.title {
    font-weight: 700;
    font-size: 20px;
    letter-spacing: 0.02em;
    margin: 0;
    margin-top: 0.5rem;
    color: #4a5568;
}

.description {
    font-weight: 400;
    font-size: 15px;
    color: #667085;
}

.description a {
    text-decoration: none;
    color: inherit;
}

.description a:hover {
    text-decoration: underline;
}

.description-list {
    list-style: none;
    padding: 0;
    margin: 0;
}

.description-list-item {
    margin-bottom: 5px;
    color: #667085;
}

.about .logo {
    box-sizing: border-box;
    width: 99px;
    height: 99px;
    border: 2px solid rgba(0, 0, 0, 0.1);
    filter: drop-shadow(4px 4px 10px rgba(0, 0, 0, 0.1));
    border-radius: 20px;
}

.about-header {
    font-family: "Google Sans";
    font-style: normal;
    font-weight: 500;
    font-size: 38px;
    line-height: 48px;
    letter-spacing: 0.02em;
    color: #303b6a;
}

.content {
    font-family: "Google Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 34px;
    letter-spacing: 0.05em;
    color: #4a5568;
}

.casestudy-header {
    font-family: "Google Sans";
    font-style: normal;
    font-size: 40px;
    line-height: 57px;
    letter-spacing: 0.02em;
}

.related-work {
    background: #ffffff;
    border: 1px solid #cccccc;
    border-radius: 15px;
    font-family: "Google Sans";
    font-style: normal;
    width: 480px;
}

.related-work-header {
    font-weight: 500;
    font-size: 24px;
    line-height: 32px;
    letter-spacing: 0.02em;
    color: #333333;
}

.related-work-content {
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.04em;
    color: #5d6b7e;
}

.related-work-btn {
    font-weight: 400;
    font-size: 16px;
    letter-spacing: 0.02em;
    color: #3364fa;
    width: fit-content;
}

.signup-btn {
    background: #ffffff;
    border-radius: 50px;
    font-family: "Google Sans";
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 25px;
    text-align: center;
}

.signup p {
    font-family: "Google Sans";
    font-style: normal;
    font-weight: 500;
    font-size: 40px;
    line-height: 51px;
}

.quote {
    font-family: "Google Sans";
    font-style: italic;
    font-weight: 500;
    font-size: 30px;
    line-height: 40px;
    letter-spacing: 0.05em;
}

.quoteby {
    font-family: "Google Sans";
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
    line-height: 40px;
    letter-spacing: 0.05em;
}

.stroke-pattern {
    background-image: url("https://assets.gallabox.com/gb-home/case-study/strokesgroup.svg");
    background-repeat: repeat;
    background-size: contain;
}

.casestudy-title {
    font-family: "Google Sans";
    font-style: normal;
    font-weight: 700;
    font-size: 19.8837px;
    line-height: 25px;
    text-align: center;
    letter-spacing: 0.06em;
}

.about-text {
    font-family: "Google Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 32px;
    line-height: 41px;
    letter-spacing: 0.02em;
    color: #4a5568;
}

.ideal-for-enterprise {
    color: white;
}

.img-component figcaption {
    font-family: "Google Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 30px;
    text-align: center;
    letter-spacing: 0.13em;
    color: #787878;
}

@media (min-width: 640px) and (max-width: 1024px) {
    .header-text {
        font-size: 36px;
        line-height: 42px;
    }

    .achievementcard {
        width: 238px;
        aspect-ratio: 1;
    }

    .about-header {
        font-size: 25px;
    }

    .about-text {
        font-size: 22px;
    }

    .casestudy-header {
        font-size: 30px;
        line-height: 35px;
        padding-bottom: 20px;
    }

    .quote {
        font-size: 25px;
        line-height: 34px;
    }

    .quoteby {
        font-size: 25px;
        font-weight: 500;
    }

    .signup-btn {
        font-size: 18px;
    }

    .signup p {
        font-size: 28px;
        line-height: 30px;
    }

    .related-work {
        width: 360px;
    }
}

@media (min-width: 640px) and (max-width: 780px) {
    .related-work {
        width: 300px;
    }

    .related-work-header {
        font-size: 20px;
    }
}

@media (max-width: 640px) {
    .header-text {
        font-size: 25px;
        line-height: 32px;
    }

    .achievementcard {
        width: 278px;
        height: 200px;
    }

    .achievementcard .title {
        font-size: 18px;
    }

    .achievementcard .count {
        font-size: 28px;
    }

    .achievementcard .by {
        font-size: 18px;
    }

    .aside {
        width: 258px;
    }

    .quote {
        font-size: 22px;
        line-height: 34px;
    }

    .quoteby {
        font-size: 22px;
        font-weight: 500;
    }

    .casestudy-header {
        font-size: 25px;
        line-height: 35px;
        padding-bottom: 20px;
    }

    .about-header {
        font-size: 28px;
    }

    .about-text {
        font-size: 25px;
    }

    .signup-btn {
        font-size: 16px;
    }

    .signup p {
        font-size: 22px;
        line-height: 30px;
    }

    .related-work {
        max-width: 360px;
    }
}
.tab-content {
    padding-top: 0.625rem;
    max-height: 0.625rem;
    display: none;

    -webkit-transition: all 200ms ease-in;
    -o-transition: all 200ms ease-in;
    transition: all 200ms ease-in;
}

.tab input:checked ~ .tab-content {
    display: block !important;
    max-height: 100vh;
    padding-top: 0 !important;
}

.tab input + label {
    transition: all 200ms ease-in;
    font-weight: 400;
}

.tab input + label:hover {
    color: #333333;
}

.tab input:checked + label {
    color: #000;
    font-weight: 500;
}

.tab input + label > .expand-toggle-icon {
    transition: transform 50ms ease-in;
}

.tab input:checked + label > .expand-toggle-icon {
    transform: rotate(45deg);
}

/* ------whats app page -------- */

.whatsapp-page .title {
    font-family: "Google Sans";
    font-style: normal;
    font-weight: 700;
    font-size: 50px;
    line-height: 64px;
    letter-spacing: 0.02em;
    color: #1a202c;
}

.whatsapp-page header {
    font-family: "Google Sans";
    font-style: normal;
    background-color: #f9fff8;
    border: 1px solid rgba(11, 95, 19, 0.1);
    border-radius: 12px;
    max-height: 415px;
}

.whatsapp-page main {
    /* font-family: 'Google Sans';
    font-style: normal; */
    background-color: white;
}

.content-with-image .title {
    font-weight: 500;
    font-size: 30.9648px;
    line-height: 39px;
    letter-spacing: 0.02em;
    color: #1a202c;
}

.content-with-image .subtitle {
    font-weight: 500;
    font-size: 16px;
    line-height: 30px;
    letter-spacing: 0.05em;
    color: #4a5568;
}

.content-with-image .image-content-wrapper .description {
    font-weight: 400;
    font-size: 16px;
    line-height: 30px;
    letter-spacing: 0.05em;
    color: #4a5568;
}

.content-with-image .list {
    font-weight: 400;
    font-size: 16px;
    line-height: 30px;
    letter-spacing: 0.05em;
    color: #4a5568;
}

.plans-price .head {
    font-weight: 700;
    font-size: 18px;
    line-height: 23px;
    text-align: center;
    letter-spacing: 0.06em;
    text-transform: uppercase;
    color: #00be99;
}

.plans-price .title {
    font-weight: 700;
    font-size: 44px;
    line-height: 56px;
    text-align: center;
    letter-spacing: 0.02em;
    color: #1d2d61;
    margin-bottom: 0.4em;
    margin-top: 0.3em;
}

.plans-price .subtitle {
    font-weight: 400;
    font-size: 18px;
    line-height: 30px;
    text-align: center;
    letter-spacing: 0.04em;
    color: #999999;
}

.pricing-card-parent .integrations img {
    width: 60.97px;
    height: 69.53px;
}

.free-tag {
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    background: #7dffb1;
    border-radius: 4px;
    padding: 2px 6px;
}

/* ----------------------------------------------- */
/* ---------chatbot-template-collections--------- */
/* ----------------------------------------------- */
.bot-template-collection-header {
    margin-top: 64px;
}

@media screen and (max-width: 765px) {
    .bot-template-collection-header {
        margin-top: 0px;
    }
}

.bot-template-collection-header .header {
    font-weight: 700;
    font-size: 40px;
    line-height: 51px;
    letter-spacing: 0.02em;
    color: #162c6f;
}

.bot-template-collection-header .description {
    font-weight: 400;
    font-size: 16px;
    line-height: 30px;
    letter-spacing: 0.05em;
    color: #4a5568;
}

.bot-template-collection-header .btn {
    font-weight: 500;
    font-size: 18.75px;
    line-height: 24px;
    letter-spacing: 0.02em;
    color: #ffffff;
    width: 174.25px;
    height: 44.83px;
    background: #3364fa;
    border-radius: 6.25px;
}

.bot-template-collection .industry-aside-list {
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0.02em;
}

.filter-tag {
    position: relative;
    background: #ffffff;
    border: 1.35322px solid #e1e1e1;
    border-radius: 8.11931px;
    cursor: pointer;
    color: #4a5568;
    padding-inline: 1em;
    width: 277px;
}

.filter-tag .filter-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 5em;
}

.filter-tag img {
    margin: 15px auto;
}

.dropdown-content-tag {
    display: none;
    z-index: 999;
    position: absolute;
    background-color: #ffffff;
    box-shadow: 0px 8px 16px 0px rgb(0 0 0 / 20%);
    border: 1.35322px solid #e1e1e1;
    border-radius: 8.11931px;
    right: 0px;
    font-size: 20px;
    font-weight: 400;
    line-height: 28px;
}

.dropdown-content-tag input {
    accent-color: #e2e8f0;
    border-radius: 2px;
    border: 2px solid #e2e8f0;
}

/* Hide the default checkbox */
.dropdown-content-tag input[type="checkbox"] {
    visibility: hidden;
}

/* Creating a custom checkbox
based on demand */
.geekmark {
    position: absolute;
    right: 9%;
    height: 20px;
    width: 20px;
    background-color: #e2e8f0;
}

/* Specify the background color to be
shown when hovering over checkbox */
/* .main:hover input ~ .geekmark {
    background-color: yellow;
} */

/* Specify the background color to be
shown when checkbox is active */
/* .dropdown-content-tag input:active ~ .geekmark {
    background-color: red;
} */

/* Specify the background color to be
shown when checkbox is checked */
.dropdown-content-tag input:checked ~ .geekmark {
    background-color: #e2e8f0;
}

/* Checkmark to be shown in checkbox */
/* It is not be shown when not checked */
.geekmark:after {
    content: "";
    position: absolute;
    display: none;
}

/* Display checkmark when checked */
.dropdown-content-tag input:checked ~ .geekmark:after {
    display: block;
}

/* Styling the checkmark using webkit */
/* Rotated the rectangle by 45 degree and 
showing only two border to make it look
like a tickmark */
.dropdown-content-tag .geekmark:after {
    left: 8px;
    bottom: 5px;
    width: 6px;
    height: 12px;
    border: solid #718096;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

.dropdown-content-tag input :hover {
    accent-color: #e2e8f0;
}

.dropdown-content-tag label {
    display: flex;
    justify-content: space-between;
    gap: 20px;
    align-items: center;
    cursor: pointer;
}

.dropdown-content-tag > div {
    padding: 5px 10px;
    margin: 7px 12px;
}

.filter-tag:hover .dropdown-content-tag {
    display: block;
}

.bot-template-collection .all-template {
    font-weight: 400;
    font-size: 32px;
    line-height: 41px;
    letter-spacing: 0.02em;
    color: #1a202c;
}

.bot-card-component {
    /* max-width: 277px; */
    min-height: 285px;
    background: #ffffff;
    border: 0.728947px solid #d3d8ea;
    border-radius: 3.64474px;
}

/* .bot-thumbnail {
    border-top-left-radius: 3.64474px;
    border-top-right-radius: 3.64474px;
    height: 142px;
    width: 100%;
} */

.bot-card-component .title {
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.02em;
    color: #4a5568;
}

.bot-card-component .description {
    font-weight: 400;
    font-size: 12.0433px;
    line-height: 15px;
    letter-spacing: 0.02em;
    color: #4a5568;
    width: 80%;
}

.bot-card-component .tag {
    font-weight: 400;
    font-size: 10.0361px;
    line-height: 13px;
    letter-spacing: 0.02em;
    color: #162c6e;
    width: fit-content;
    /* height: 17px; */
    background-color: #ced9fb;
    border-radius: 12px;
}

.bot-card-component .try {
    font-weight: 400;
    font-size: 13.1211px;
    line-height: 17px;
    text-align: center;
    letter-spacing: 0.02em;
    color: #7e8695;
    width: 50px;
    height: 23.89px;
    border: 1px solid #c8cedb;
    border-radius: 18.2237px;
}

.bot-card-component .view-count {
    font-weight: 400;
    font-size: 8.02888px;
    line-height: 10px;
    text-align: center;
    letter-spacing: 0.02em;
    color: #7e8695;
}

.bot-template-collection-bottom-section .category-title {
    font-weight: 400;
    font-size: 32px;
    line-height: 41px;
    letter-spacing: 0.02em;
    color: #1a202c;
}

.bot-rating-card {
    /* width: 380px; */
    background: #ffffff;
    border: 1px solid #d3d8ea;
    border-radius: 5px;
}

.bot-rating-card .head-img {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}

.bot-rating-card .title {
    font-weight: 400;
    font-size: 18px;
    line-height: 23px;
    letter-spacing: 0.02em;
    color: #4a5568;
}

.bot-rating-card .use {
    width: 83px;
    height: 33px;
    background: #3364fa;
    border: 1px solid #3364fa;
    border-radius: 25px;
    font-weight: 500;
    font-size: 18px;
    line-height: 23px;
    text-align: center;
    letter-spacing: 0.02em;
    color: #ffffff;
}

.bot-rating-card .view-count {
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    letter-spacing: 0.02em;
    color: #4a5568;
}

.buildnow-blue-section .content {
    font-weight: 700;
    font-size: 20px;
    line-height: 25px;
    letter-spacing: 0.02em;
    color: #ffffff;
}

.buildnow-blue-section button {
    width: 150px;
    font-weight: 700;
    font-size: 20px;
    letter-spacing: 0.02em;
    color: #3364fa;
    background: #ffffff;
    border-radius: 32.3994px;
    padding: 0.25em;
}

.slide-count {
    width: 60px;
    height: 60px;
    background-color: #ffffff;
    border: 1px solid rgba(51, 100, 250, 0.1);
    box-shadow: 2px 2px 10px rgba(51, 100, 250, 0.2), inset 0px 0px 10px rgba(122, 152, 244, 0.1);
    border-radius: 50%;
    color: #3364fa;
    font-weight: bold;
}

.left-arrow,
.right-arrow {
    width: 30px;
    height: 30px;
    background: #ffffff;
    border: 1px solid rgba(51, 100, 250, 0.1);
    border-radius: 50%;
}

.browseByUseCase {
    max-width: 830px;
    margin-inline: auto;
    text-align: center;
    margin-block-end: 12em;
    margin-block-start: 6em;
}

.browseByUseCase .wrapper {
    display: flex;
    margin-top: 2.5em;
    gap: 2em;
    justify-content: center;
}

@media screen and (max-width: 765px) {
    .browseByUseCase .wrapper {
        flex-direction: column;
    }
}

.browseByUseCase .each-tag {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-weight: 400;
    font-size: 15.5734px;
    color: #2d3748;
}

.browseByUseCase .each-tag .icon {
    width: 110px;
    height: 100px;
    aspect-ratio: 1;
    background: #ffffff;
    border: 0.959777px solid #b4c0ea;
    border-radius: 200px;
    cursor: pointer;
    margin-bottom: 0.75em;
    display: grid;
    place-content: center;
}

/* -------------------------- */
/* ----bot-template-page----- */
/* -------------------------- */
.bot-template-page-header .header {
    font-weight: 700;
    font-size: 45px;
    line-height: 57px;
    letter-spacing: 0.02em;
    color: #162c6f;
}

.bot-template-page-header li {
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    letter-spacing: 0.02em;
    color: #162c6e;
}

.bot-template-page-header .description {
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.02em;
    color: #162c6f;
}

.bot-template-page-header .btn {
    font-weight: 600;
    font-size: 18px;
    color: #ffffff;
    /* width: 200px;
    height: 48px; */
    background: #0083ff;
    border-radius: 6px;
    padding: 0.5em 1em;
}

.bot-template-page .tag {
    font-weight: 400;
    font-size: 12px;
    line-height: 13px;
    letter-spacing: 0.02em;
    color: #4a5568;
    width: fit-content;
    /* height: 17px; */
    background-color: #fff;
    border: 1px solid #cbd5e0;
    border-radius: 4px;
    padding: 0.5em;
}

.bot-template-main .title {
    font-weight: 400;
    font-size: 32px;
    line-height: 41px;
    letter-spacing: 0.02em;
    color: #1a202c;
}

.bot-template-main .description {
    font-weight: 400;
    font-size: 16px;
    line-height: 30px;
    letter-spacing: 0.05em;
    color: #4a5568;
}

.bot-template-features h2 {
    font-weight: 400;
    font-size: 32px;
    line-height: 41px;
    letter-spacing: 0.02em;
    color: #1a202c;
}

.bot-template-features .content {
    font-weight: 400;
    font-size: 16px;
    line-height: 30px;
    letter-spacing: 0.05em;
    color: #4a5568;
}

.aside-industries h2 {
    font-weight: 400;
    font-size: 32px;
    line-height: 41px;
    letter-spacing: 0.02em;
    color: #1a202c;
}

.aside-industries .content {
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.05em;
    color: #4a5568;
}

.logo-div {
    width: 56.18px;
    height: 56.18px;
    background: #ffffff;
    border: 0.780347px solid rgba(74, 85, 104, 0.1);
    border-radius: 7.80347px;
}

.logo-div img {
    display: block;
}

@media (max-width: 645px) {
    .bot-template-page-header .header {
        font-size: 28px;
    }
}

.about-plans .title {
    font-weight: 700;
    font-size: 36px;
    line-height: 46px;
    letter-spacing: 0.02em;
    color: #1d2d61;
}

.about-plans .list {
    font-weight: 400;
    font-size: 18px;
    line-height: 23px;
    letter-spacing: 0.02em;
    color: #333333;
    margin-top: 0.3em;
}

@media (max-width: 645px) {
    .whatsapp-page .title {
        font-size: 30px;
        line-height: 40px;
        text-align: center;
        margin-top: 1em;
    }
}

/* ---pricing-card---- */
.pricing-card-parent {
    overflow: visible !important;
}

.plan-slider {
    overflow: visible !important;
    width: 300px !important;
    max-width: 645px !important;
    padding-inline: 4em !important;
}

/* ----------------------------------------------------- */
/* ---------chatbot-template-collections revamp --------- */
/* ----------------------------------------------------- */

.bot-template-collection-header h1 {
    font-weight: 700;
    font-size: 60px;
    line-height: 60px;
    letter-spacing: 0.02em;
}

.bot-template-collection-header h1::before {
    content: url("https://assets.gallabox.com/gb-home/bot-templates/three-yellow-lines.svg");
    position: relative;
    right: 5px;
}

.bot-template-collection-header h1::after {
    /* content: url("https://assets.gallabox.com/gb-home/bot-templates/robo-with-yellow-line.svg");
    position: relative;
    right: 800px; */
}

.bot-template-collection-header .whatsapp-text::after {
    content: url("https://assets.gallabox.com/gb-home/bot-templates/yellow-line-with-whatsapp.svg");
    position: relative;
    top: -10px;
    left: -8px;
}

.bot-template-collection .sub-heading {
    font-weight: 700;
    font-size: 30px;
    color: #001a33;
}

.bot-template-collection-header .description {
    font-weight: 400;
    font-size: 16px;
    line-height: 30px;
    letter-spacing: 0.05em;
    color: #4a5568;
}

.bot-template-collection-header .btn {
    font-weight: 500;
    font-size: 18.75px;
    line-height: 24px;
    letter-spacing: 0.02em;
    color: #ffffff;
    width: 174.25px;
    height: 44.83px;
    background: #3364fa;
    border-radius: 6.25px;
}

.bot-template-collection .all-template {
    font-weight: 400;
    font-size: 32px;
    line-height: 41px;
    letter-spacing: 0.02em;
    color: #1a202c;
}

.bot-card-component {
    background: #ffffff;
    border: 1px solid #d3d8ea;
    border-radius: 5px;
    max-width: 380px;
    /* height: 436px; */
}

.bot-thumbnail {
    height: 200px;
    width: 100%;
    object-fit: cover;
}

.bot-card-component .title {
    font-weight: 700;
    font-size: 1.25rem;
    line-height: 25px;
    letter-spacing: 0.02em;
    color: #2d3748;
}

.bot-card-component .description {
    font-weight: 400;
    font-size: 1.029rem;
    line-height: 21px;
    letter-spacing: 0.02em;
    color: #4a5568;
    width: 100%;
    height: 66px;
    overflow: hidden;
}

.bot-card-component .tag {
    font-weight: 400;
    font-size: 0.75rem;
    line-height: 16px;
    color: #4a5568;
    width: fit-content;
    background-color: #cbd5e0;
    border-radius: 4px;
}

.bot-card-component .try {
    font-weight: 500;
    font-size: 0.875rem;
    text-align: center;
    letter-spacing: 0.02em;
    color: #0083ff;
    width: 61px;
    height: 32px;
    border: 1px solid #0083ff;
    border-radius: 5px;
    background-color: #fff;
}

.bot-card-component .view-count {
    font-weight: 400;
    font-size: 10.9747px;
    line-height: 10px;
    text-align: center;
    letter-spacing: 0.02em;
    color: #718096;
}

.bot-template-collection-bottom-section .category-title,
.bot-template-main .title,
.bot-template-features .category-title {
    font-weight: 700;
    font-size: 30px;
    line-height: 41px;
    letter-spacing: 0.02em;
    color: #001a33;
}

.bot-rating-card {
    /* width: 380px; */
    background: #ffffff;
    border: 1px solid #d3d8ea;
    border-radius: 5px;
}

.bot-rating-card .head-img {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}

.bot-rating-card .title {
    font-weight: 400;
    font-size: 18px;
    line-height: 23px;
    letter-spacing: 0.02em;
    color: #4a5568;
}

.bot-rating-card .use {
    width: 83px;
    height: 33px;
    background: #3364fa;
    border: 1px solid #3364fa;
    border-radius: 25px;
    font-weight: 500;
    font-size: 18px;
    line-height: 23px;
    text-align: center;
    letter-spacing: 0.02em;
    color: #ffffff;
}

.bot-rating-card .view-count {
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    letter-spacing: 0.02em;
    color: #4a5568;
}

.buildnow-blue-section .content {
    font-weight: 700;
    font-size: 20px;
    line-height: 25px;
    letter-spacing: 0.02em;
    color: #ffffff;
}

.buildnow-blue-section button {
    width: 150px;
    font-weight: 700;
    font-size: 20px;
    letter-spacing: 0.02em;
    color: #3364fa;
    background: #ffffff;
    border-radius: 32.3994px;
    padding: 0.25em;
}

.slide-count {
    width: 60px;
    height: 60px;
    background-color: #ffffff;
    border: 1px solid rgba(51, 100, 250, 0.1);
    box-shadow: 2px 2px 10px rgba(51, 100, 250, 0.2), inset 0px 0px 10px rgba(122, 152, 244, 0.1);
    border-radius: 50%;
    color: #3364fa;
    font-weight: bold;
}

.left-arrow,
.right-arrow {
    width: 30px;
    height: 30px;
    background: #ffffff;
    border: 1px solid rgba(51, 100, 250, 0.1);
    border-radius: 50%;
}

/* --- drop down ----- */
.dropbtn {
    font-size: 16px;
    border: none;
    cursor: pointer;
    /* width: fit-content; */
}

.dropdown {
    position: relative;
    display: inline-block;
    background: #ffffff;
    border: 1.35322px solid #e1e1e1;
    border-radius: 8.11931px;
    padding: 0.5em 1em;
    width: 277px;
}

.dropdown-content {
    display: none;
    position: absolute;
    right: 0px;
    top: 50px;
    background-color: #ffffff;
    width: 200px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
    border: 1.35322px solid #e1e1e1;
    border-radius: 8.11931px;
    width: 277px;
}

.dropdown-content a {
    padding: 7px 12px;
    text-decoration: none;
    display: flex;
    margin: 0.25em 0.5em;
    border-radius: 6px;
    column-gap: 14px;
}

.dropdown-content a:hover {
    background-color: #e2e8f0;
}

.dropdown:hover .dropdown-content {
    display: block;
}

@media (max-width: 745px) {
    .bot-template-collection-header h1 {
        font-size: 2rem;
        line-height: 38px;
    }

    .bot-template-collection-header .whatsapp-text::after,
    .bot-template-collection-header h1::after,
    .bot-template-collection-header h1::before {
        content: "";
    }

    .bot-template-collection .sub-heading,
    .bot-template-collection-header .sub-heading {
        font-size: 22px;
    }
}

/* -------- pricing page revamp ------------ */
.pricing-header {
    background-color: var(--blue-500);
    background-image: url("https://assets.gallabox.com/gb-home/pricing-page/pricing-page-patten.svg");
}

.pricing-header h1 {
    font-size: 2.75rem;
    line-height: 56px;
    letter-spacing: 0.02em;
}

.pricing-tabs {
    background-color: var(--blue-500);
    width: 300px;
    padding: 0.5em 1.5em;
    box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.2);
    border-radius: 1.583em;
    color: var(--white);
    font-size: 1rem;
}

.pricing-card {
    background-color: #ffffff;
    border: 1px solid #e2e8f0;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06);
    border-radius: 6px;
    height: 950px;
    padding: 1.5em;
}

.pricing-card-sec1 {
    height: 350px;
}

@media (max-width: 1024px) {
    .pricing-card {
        background-color: #ffffff;
        border: 1px solid #e2e8f0;
        box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06);
        border-radius: 6px;
        height: auto;
        padding: 1.5em;
    }

    .pricing-card-sec1 {
        height: fit-content;
    }
}

.pricing-card .price {
    font-size: 2.375em;
    color: var(--gray-900);
    line-height: 0.02em;
    line-height: 48px;
}

.pricing-card .price-description {
    font-weight: 400;
    font-size: 0.875em;
    line-height: 24px;

    letter-spacing: 0.02em;
    color: #718096;
}

.pricing-card .try-btn {
    font-weight: 500;
    font-size: 1rem;
    border: 1px solid #0a81ff;
    color: #08519f;
    border-radius: 6px;
    padding: 0.5em 1em;
}

.pricing-card .try-btn:hover {
    background-color: var(--blue-500);
    border: 1px solid var(--blue-500);
    color: #fff;
}

.pricing-card .try-btn-new {
    font-weight: 500;
    font-size: 1rem;
    color: white;
    border-radius: 6px;
    padding: 0.5em 1em;
}
.pricing-card .try-btn-new:hover {
    background-color: var(--blue-600);
    border: 1px solid var(--blue-600);
    color: white;
    background-color: #3182ce;
}

.pricing-card .try-btn-custom {
    font-weight: 500;
    font-size: 1rem;
    color: #3182ce;
    border-radius: 6px;
    padding: 0.5em 1em;
}
.pricing-card .try-btn-custom:hover {
    color: #3182ce;
    background-color: whitesmoke;
}

.pricing-card .about {
    font-weight: 400;
    font-size: 0.813em;
    line-height: 20px;
    letter-spacing: 0.02em;
    color: #4a5568;
}

.pricing-card .tagBox {
    background-color: #edf2f7;
    border-radius: 3px;
    font-weight: 400;
    font-size: 0.875em;
    line-height: 18px;
    letter-spacing: 0.02em;
    color: #4a5568;
    width: fit-content;
}

.pricing-card-ar {
    background-color: #ffffff;
    border: 1px solid #e2e8f0;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06);
    border-radius: 6px;
    height: 750px;
    padding: 1.5em;
}

.pricing-card-ar .price {
    font-size: 2.375em;
    color: var(--gray-900);
    line-height: 0.02em;
    line-height: 48px;
}

.pricing-card-ar .price-description {
    font-weight: 400;
    font-size: 0.875em;
    line-height: 24px;

    letter-spacing: 0.02em;
    color: #718096;
}

.pricing-card-ar .try-btn {
    font-weight: 500;
    font-size: 1rem;
    border: 1px solid #0a81ff;
    color: #08519f;
    border-radius: 6px;
    padding: 0.5em 1em;
}

.pricing-card-ar .try-btn:hover {
    background-color: var(--blue-500);
    border: 1px solid var(--blue-500);
    color: #fff;
}

.pricing-card-ar .try-btn-new {
    font-weight: 500;
    font-size: 1rem;
    color: white;
    border-radius: 6px;
    padding: 0.5em 1em;
}
.pricing-card-ar .try-btn-new:hover {
    background-color: var(--blue-600);
    border: 1px solid var(--blue-600);
    color: white;
    background-color: #3182ce;
}

.pricing-card-ar .try-btn-custom {
    font-weight: 500;
    font-size: 1rem;
    color: #3182ce;
    border-radius: 6px;
    padding: 0.5em 1em;
}
.pricing-card-ar .try-btn-custom:hover {
    color: #3182ce;
    background-color: whitesmoke;
}

.pricing-card-ar .about {
    font-weight: 400;
    font-size: 0.813em;
    line-height: 20px;
    letter-spacing: 0.02em;
    color: #4a5568;
}

.pricing-card-ar .tagBox {
    background-color: #edf2f7;
    border-radius: 3px;
    font-weight: 400;
    font-size: 0.875em;
    line-height: 18px;
    letter-spacing: 0.02em;
    color: #4a5568;
    width: fit-content;
}

.pricing .second-head {
    font-weight: 700;
    font-size: 30px;
    line-height: 38px;
    letter-spacing: 0.02em;
    color: #1d2d61;
}

.pricing .conversation-cost {
    background-color: #edf2f7;
    border: 1px solid #cbd5e0;
    border-radius: 6px;
}

.pricing-tooltip-content {
    display: none;
    position: absolute;

    width: 120px;
    left: -340%;
    z-index: 2;
    bottom: 100%;
    background-color: #1a202c;
    border: 1px solid rgb(243 244 246);
    padding: 6px 8px;
    padding-left: 10px;
    font-weight: 400;
    font-size: 14px;
    color: #edf2f7;
    border-radius: 10px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    bottom: 35px;
}

.pricing-tooltip-content-new {
    display: none;
    position: absolute;
    width: 200px;
    left: -580%;

    bottom: 100%;
    background-color: #1a202c;
    border: 1px solid rgb(243 244 246);
    padding: 6px 8px;
    padding-left: 10px;
    font-weight: 400;
    font-size: 14px;
    color: #edf2f7;
    border-radius: 10px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    bottom: 35px;
}

.tooltip-table {
    position: absolute;
    background-color: #333;
    color: #fff;
    padding: 8px;
    border-radius: 4px;
    font-size: 14px;
    white-space: normal;
    width: 250px;
    text-align: center;
    transform: translate(-50%, -100%);
    top: 0;
    left: 50%;
}

.tooltip-table::before {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -8px;
    border-width: 8px;
    border-style: solid;
    border-color: #333 transparent transparent transparent;
}

.pricing-tooltip-icon:hover + .pricing-tooltip-content {
    display: block;
}

.pricing-tooltip-icon-new:hover + .pricing-tooltip-content-new {
    display: block;
}

.pricing-tooltip-content::before {
    content: "";
    border: 8px solid transparent;
    border-top: 8px solid #1a202c;
    position: absolute;
    top: 100%;
    left: 48%;
    transform: translateX(-50%);
}

.pricing-tooltip-content-new::before {
    content: "";
    border: 8px solid transparent;
    border-top: 8px solid #1a202c;
    position: absolute;
    top: 100%;
    left: 48%;
    transform: translateX(-50%);
}

.pricing-tooltip-icon {
    height: 15px !important;
}

.pricing-tooltip-icon-new {
    height: 20px !important;
}

@media (max-width: 745px) {
    .pricing-header h1 {
        font-size: 1.75rem;
        line-height: 36px;
        padding-inline: 0.75em;
    }

    .pricing-tabs {
        width: 80vw;
    }

    .plan-slider {
        overflow: visible !important;
        width: 300px !important;
        max-width: 645px !important;
        padding-inline: 4em !important;
    }

    .pricing-tooltip-content::before {
        content: "";
        border: 8px solid transparent;
        border-top: 8px solid #1a202c;
        position: absolute;
        transform: translateX(-50%);
        top: 96%;
        left: 50%;
        rotate: -6deg;
    }

    .pricing-tooltip-content-new::before {
        content: "";
        border: 8px solid transparent;
        border-top: 8px solid #1a202c;
        position: absolute;
        transform: translateX(-50%);
        top: 96%;
        left: 50%;
        rotate: -6deg;
        flex-shrink: 0;
        display: block;
    }
}

/* The container */
.pricing-tabs .container {
    display: block;
    position: relative;
    /* padding-left: 35px; */
    /* margin-bottom: 12px; */
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

/* Hide the browser's default radio button */
.pricing-tabs .container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
}

/* Create a custom radio button */
.pricing-tabs .checkmark {
    position: absolute;
    top: 4px;
    left: -20px;
    height: 16px;
    width: 16px;
    background-color: #0083ff;
    border: 2px solid #e2e8f0;
    border-radius: 50%;
}

/* On mouse-over, add a grey background color */
.pricing-tabs .container:hover input ~ .checkmark {
    background-color: #0083ff;
    border: 2px solid #e2e8f0;
}

/* When the radio button is checked, add a blue background */
.pricing-tabs .container input:checked ~ .checkmark {
    background-color: #f6e05e;
    border: none;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.pricing-tabs .checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the indicator (dot/circle) when checked */
.pricing-tabs .container input:checked ~ .checkmark:after {
    display: block;
}

/* Style the indicator (dot/circle) */
.pricing-tabs .container .checkmark:after {
    top: 0px;
    left: 0x;
    margin-top: 4px;
    margin-left: 4px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: white;
}

@media screen and (max-width: 426px) {
    .pricing-tabs .container {
        padding: 0;
    }
}

/* ----- logos slider  ------ */
.IntegrationSlidertitle {
    font-weight: 700;
    font-size: 40px;
    line-height: 57px;
    letter-spacing: 0.02em;
    color: #000;
    text-align: center;
}

.sliderparent::before {
    content: "";
    position: absolute;
    left: 0px;
    width: 30px;
    height: 165px;
    z-index: 5;
    background-color: white;
    box-shadow: 10px 0px 30px 50px #fff;
}

.sliderparent::after {
    content: "";
    position: absolute;
    right: 0px;
    width: 30px;
    height: 165px;
    z-index: 5;
    background-color: white;
    box-shadow: 10px 0px 30px 50px #fff;
}

@media (max-width: 720px) {
    .IntegrationSlidertitle {
        font-size: 22px;
        line-height: 32px;
        margin-bottom: 0;
        position: relative;
        top: 10px;
        width: 50%;
        margin-inline: auto;
    }

    .sliderparent::before {
        content: "";
        position: absolute;
        left: 0px;
        height: 165px;
        z-index: 5;
        background-color: white;
        box-shadow: -12px 0 36px 48px #fff;
        width: 18px;
    }

    .sliderparent::after {
        content: "";
        position: absolute;
        right: 0px;
        height: 165px;
        z-index: 5;
        background-color: white;
        box-shadow: 12px 0 36px 48px #fff;
        width: 18px;
    }
}

.parentdiv {
    position: relative;
}

/* ------------------------------------- */
/* ------------- about page - ---------- */
/* ------------------------------------- */

.about-page .small-head-text {
    font-weight: 700;
    font-size: 18px;
    line-height: 23px;
    /* identical to box height */

    text-align: center;
    letter-spacing: 0.06em;
    text-transform: uppercase;

    /* blue/500 */

    color: #0083ff;
}

.about-page .sub-head-text {
    font-family: "Raleway";
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 38px;
    /* identical to box height */

    text-align: center;
    letter-spacing: 0.02em;
    margin: 0.25em 0;
}

.about-page .founder-name {
    font-family: "Raleway";
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    line-height: 28px;
    /* identical to box height, or 127% */

    text-align: center;
    letter-spacing: 0.02em;

    /* white */

    color: #ffffff;
}

.about-page .founder-position {
    font-family: "Raleway";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    letter-spacing: 0.02em;

    color: #ffffff;
}

.about-page .founder-card {
    width: 365px;
}

.about-page .founder-image {
    height: 250px;
}

.about-page .cta-section {
    background: #ffed82;
    border-radius: 20px;
}

.about-page .cta-section h3 {
    font-family: "Google Sans";
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 31px;
    letter-spacing: 0.02em;

    /* gray/700 */

    color: #2d3748;
}

.about-page .cta-section p {
    font-family: "Google Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 28px;
    /* or 156% */

    letter-spacing: 0.02em;

    /* gray/700 */

    color: #2d3748;
}

.about-page .each-value-title {
    font-family: "Google Sans";
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 23px;
    /* identical to box height */

    letter-spacing: 0.04em;

    color: #000000;
}

.about-page .each-value-content {
    font-family: "Google Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    /* or 157% */

    letter-spacing: 0.04em;

    color: #666666;
}

.about-page h1 {
    font-family: "Raleway";
    font-style: normal;
    font-weight: 800;
    font-size: 42px;
    line-height: 58px;
    /* or 138% */

    text-align: center;
    letter-spacing: 0.02em;

    /* gray/900 */

    color: #171923;
}

.about-page .header-content {
    font-family: "Google Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 32px;
    /* or 160% */

    letter-spacing: 0.02em;

    /* gray/700 */

    color: #2d3748;
    margin-bottom: 1.5em;
}

.about-page .header-image {
    width: 660px;
    height: 430px;
    box-shadow: 5px 5px 0 3px #0083ff;
    border-radius: 24px;
    position: relative;
    z-index: 1;
}

.about-page .header-image::before {
    content: "";
    width: 100%;
    height: 100%;
    border: 2px solid #ffed82;
    position: absolute;
    right: 13px;
    bottom: 13px;
    border-radius: 24px;
    z-index: -1;
}

.about-page .about-slider {
    width: 490px;
}

.home-banner {
    position: absolute;
    z-index: -1;
    height: 80%;
}

.about-page header {
    background-image: url("https://assets.gallabox.com/gb-home/about-revamap/desktop-bg.svg");
    background-repeat: no-repeat;
    background-size: 100vw;
}

.image-item {
    padding: 20px 0 20px 20px;
}

.ghost-btn {
    border: 1px solid #0083ff;
    padding: 10px 24px;
    border-radius: 6px;
    background-color: transparent;
    color: #0083ff;
    font-weight: 500;
}

.wa-mobile-a {
    flex-basis: 60%;
}

.green-underline::after {
    content: "";
    width: 100%;
    position: absolute;
    display: block;
    z-index: -1;
    bottom: 7px;
    left: 0px;
    border-bottom: 6px solid #55cd6c;
}

.white-testimonial-box {
    background-color: white;
    width: 100%;
    max-width: 380px;
    box-shadow: 0px 19px 92px rgba(99, 103, 117, 0.13);
    padding: 30px;
}

.admissionProcessBox {
    box-shadow: 0px 4px 41px rgba(0, 0, 0, 0.07);
    border-radius: 6px;
    padding: 20px 39px;
    width: 100%;
    max-width: 278px;
    font-weight: 400;
    font-size: 16px;
    color: #444444;
    transition: all 200ms ease-in-out;
    display: flex;
    align-items: center;
}

.admissionProcessBox:hover {
    box-shadow: 0px 0px 51px 4px rgba(0, 0, 0, 0.168);
    color: white;
    background-color: #3b82f6;
    transform: scale(104%);
}

.admissionProcess .blk2 {
    flex-basis: 55%;
}

.edu-bot-spl-blk-box {
    box-shadow: 0px 4px 41px rgba(0, 0, 0, 0.07);
    border-radius: 6px;
    padding: 30px;
    width: 100%;
    max-width: 470px;
}

.edu-bot-spl-blk ul li {
    padding-left: 0px;
    margin: 0px;
}

.edu-bot-spl-blk ul {
    padding-right: 0px;
}

.cardSecond {
    margin-top: -5px;
    line-height: 50px;
}

.sky-blue-cta {
    background: #edf7ff;
    padding: 43px 10px;
    width: 100%;
    max-width: 1200px;
}

.text-gray-600 {
    color: #4a5568 !important;
}

.slider-and-content-a .dots {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}
.slider-and-content-a-home .dots {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

.slider-and-content-a .dot {
    background-color: #98a2b3;
    border: none;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    margin: 0 5px;
    padding: 5px;
    cursor: pointer;
}

.slider-and-content-a-home .dot {
    background-color: #98a2b3;
    border: none;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    margin: 0 5px;
    padding: 5px;
    cursor: pointer;
}

.slider-and-content-a .dot.active {
    background-color: #344054;
}

.slider-and-content-a-home .dot.active {
    background-color: #344054;
}

.sky-blue-cta .stars {
    bottom: -36px;
    left: 56px;
}

.sky-blue-cta .smile {
    right: 60px;
    top: -45px;
}

.sky-blue-cta .diamond {
    right: -27px;
    top: -12px;
}

.white-btn {
    background: white;
    color: #0083ff;
    padding: 10px 44px;
    border-radius: 6px;
}

.ghost-blue-hover:hover {
    transition: background-color 0.3s linear;
    color: #fff !important;
    background-color: #0083ff;
    box-shadow: 0 0 13px -8px #0083ff;
}

.yellow-new-cta {
    background: #feee95;
    /* padding: 43px 10px; */
    width: 100%;
    max-width: 1200px;
}

.yellow-new-cta .stars {
    bottom: -36px;
    left: 56px;
}

.yellow-new-cta .smile {
    right: 60px;
    top: -45px;
}

.yellow-new-cta .diamond {
    right: -27px;
    top: -12px;
}

.sm-green-underline::after {
    content: "";
    width: 100%;
    position: absolute;
    display: block;
    z-index: -1;
    bottom: 7px;
    left: 0px;
    border-bottom: 6px solid #55cd6c;
}

.gray-bg-testimonial {
    display: grid;
    grid-template-rows: 52px 128px;
}

.edu-bot .green-underline {
    position: absolute;
    right: -33px;
    bottom: 4px;
}

.edu-bot .green-arrow {
    position: absolute;
    right: -186px;
    bottom: 0px;
}

.slider-and-content-a {
    flex-basis: 58%;
}
.img-content-button {
    display: flex;
    gap: 30px;
}

.img-content-button-a {
    flex-basis: 100%;
}

.slider-and-content-a > div {
    width: 100%;
    max-width: 600px;
}

.content-button {
    padding: 16px 24px;
    transition: border 1s cubic-bezier(0.19, 1, 0.22, 1);
}
.slider-and-content-b {
    flex-basis: 40%;
}

.wa-control-img {
    display: inline-block;
}

@media (max-width: 650px) {
    .yellow-new-cta {
        background: #feee95;
        /* padding: 43px 40px; */
        width: 100%;
        max-width: 1200px;
    }

    .yellow-new-cta .stars {
        bottom: -28px;
        left: 5px;
        width: 24%;
    }

    .yellow-new-cta .smile {
        right: 1px;
        top: -35px;
        width: 20%;
    }

    .yellow-new-cta .diamond {
        right: -27px;
        top: -12px;
    }

    .edu-bot .green-underline {
        position: absolute;
        right: 0px;
        bottom: 4px;
    }

    .gray-bg-testimonial {
        display: grid;
        grid-template-rows: auto;
    }

    .sm-green-underline::after {
        content: "";
        width: 100%;
        position: absolute;
        display: block;
        z-index: -1;
        bottom: 7px;
        left: 0px;
        border-bottom: 6px solid #55cd6c;
    }

    .cardSecond {
        margin-top: 0px;
        line-height: 45px;
    }

    .admissionProcess .blk2 {
        flex-basis: 100%;
    }

    .slider-and-content-a {
        flex-basis: 58%;
    }

    .img-content-button {
        display: flex;
        gap: 30px;
    }

    .img-content-button-a {
        flex-basis: 100%;
    }

    .slider-and-content-a > div {
        width: 100%;
        max-width: 600px;
    }

    .content-button {
        padding: 16px 24px;
        transition: border 1s cubic-bezier(0.19, 1, 0.22, 1);
    }

    .slider-and-content-b {
        flex-basis: 40%;
    }

    .fade-intro {
        opacity: 0;
        transform: translateY(-50px);
        transition: all 50ms cubic-bezier(0.19, 1, 0.22, 1);
    }

    .shown-img {
        transform: translateY(0);
        opacity: 1;
    }

    .ghost-blue-hover:hover {
        transition: background-color 300ms linear;
        color: white !important;
        background-color: #0083ff;
        box-shadow: 0px 0px 13px -8px #0083ff;
    }

    .ads-sec-title {
        font-size: 44px;
    }

    .logo-mobile::before {
        content: "";
        position: absolute;
        left: 0px;
        height: 165px;
        z-index: 5;
        background-color: white;
        box-shadow: -12px 0 36px 48px #fff;
        width: 18px;
    }

    .logo-mobile::after {
        content: "";
        position: absolute;
        right: 0px;
        height: 165px;
        z-index: 5;
        background-color: white;
        box-shadow: 12px 0 36px 48px #fff;
        width: 18px;
        top: -30px;
    }

    .ylw-cta-no-input {
        flex-basis: 90%;
    }

    .ads-sec-title {
        font-size: 34px;
    }

    .propleaf-video-cont {
        height: 205px !important;
    }

    .slider-and-content-a > div {
        width: 91vw;
        margin: auto;
    }

    .slider-and-content-b {
        flex-basis: 100%;
    }

    .wa-mobile-a {
        flex-basis: 100%;
    }

    .about-page .about-slider {
        width: 350px;
    }

    .about-page .small-head-text {
        font-size: 14px;
    }

    .about-page .sub-head-text {
        font-family: "Raleway";
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 23px;
        text-align: center;
        letter-spacing: 0.02em;
    }

    .about-page h1 {
        font-weight: 800;
        font-size: 30px;
        line-height: 43px;
        /* or 143% */

        text-align: center;
        letter-spacing: 0.02em;
    }

    .about-page .header-content {
        font-weight: 400;
        font-size: 16px;
        line-height: 26px;
        /* or 162% */

        letter-spacing: 0.02em;
    }

    .about-page .header-image {
        width: 339.71px;
        height: 219.4px;
        box-shadow: 3px 3px 0 1px #0083ff;
        border-radius: 24px;
        margin-inline: auto;
    }

    .about-page .founder-card {
        width: 300px;
    }

    .about-page .founder-image {
        height: 210px;
    }

    .about-page header {
        background-image: url("https://assets.gallabox.com/gb-home/about-revamap/mobile-bg.svg");
        background-repeat: no-repeat;
        background-size: 100vw;
    }

    .about-page .header-image::before {
        content: "";
        width: 100%;
        height: 100%;
        border: 2px solid #ffed82;
        position: absolute;
        right: 7px;
        bottom: 7px;
        border-radius: 24px;
        z-index: -1;
    }

    .image-item {
        padding: 20px 0 20px 0px;
    }
}

.truncate-two-line {
    display: -webkit-box;
    line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.truncate-three-line {
    display: -webkit-box;
    line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}
