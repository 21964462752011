.fader {
    height: 460px;
    position: relative;
}
.fader-mobile {
    height: 556px;
    position: relative;
}

.fader__slide {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
}
