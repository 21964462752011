.mobile-no::after {
    content: "Join our Community";
}

.animate-img {
    transform: perspective(750px) translate3d(0px, -25px, -250px) rotateX(27deg) scale(1.5, 1.5);
    transition: 0.4s ease-in-out transform;
}

.animate-img.viewed {
    transform: translate3d(0px, 0px, -250px);
}

.fade-img {
    opacity: 0;
    transform: scale(0);
    transition: none;
}

.fade-img:first-of-type {
    top: 15%;
    left: -10%;
}

.fade-img:nth-last-of-type(2) {
    top: 22%;
    right: -2%;
}

.fade-img:nth-last-of-type(3) {
    bottom: 8%;
    right: -4%;
}

.fade-img:nth-last-of-type(4) {
    bottom: 10%;
    left: -16%;
}

.fade-img.viewed {
    transform: scale(1);
    transition: 0.5s ease-in-out all;
    opacity: 1;
}

@media (max-width: 767px) {
    [data-aos^="fade"][data-aos^="fade"].aos-animate.phone {
        transform: translateZ(0) translateY(-100%);
    }
}
