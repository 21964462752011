.box {
    position: relative;
    width: 100%;
    height: 3px;
    background: #ccc;
}
.box.loading {
    background: rgba(51, 101, 250, 0.2);
}
.box.inactive {
    background: #cbd5e0;
}

.box-border-top {
    position: absolute;
    top: 0;
    left: 0;
    height: 3px;
    width: 100%;
}

.box-border-top.inactive {
    background: #cbd5e0;
}

.box-border-top.loading {
    background: #3364fa;
    width: 0;
    animation: fill 7s linear forwards;
}

@keyframes fill {
    from {
        width: 0;
    }
    to {
        width: 100%;
    }
}
