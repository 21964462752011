@font-face {
    font-family: "Merriweather";
    src: url("/fonts/Merriweather/Merriweather-Bold.ttf");
    font-weight: 700;
    font-display: swap;
}
/* @font-face {
    font-family: "Merriweather";
    src: url("/fonts/Merriweather/Merriweather-Semibold.ttf");
    font-weight: 600;
    font-display: swap;
}
@font-face {
    font-family: "Merriweather";
    src: url("/fonts/Merriweather/Merriweather-Medium.ttf");
    font-weight: 500;
    font-display: swap;
} */
@font-face {
    font-family: "Merriweather";
    src: url("/fonts/Merriweather/Merriweather-Regular.ttf");
    font-weight: 400;
    font-display: swap;
}
@font-face {
    font-family: "Source Sans Pro";
    src: url("/fonts/Source_Sans_Pro/SourceSansPro-Bold.ttf");
    font-weight: 700;
    font-display: swap;
}
@font-face {
    font-family: "Source Sans Pro";
    src: url("/fonts/Source_Sans_Pro/SourceSansPro-SemiBold.ttf");
    font-weight: 600;
    font-display: swap;
}
/* @font-face {
    font-family: "Source Sans Pro";
    src: url("/fonts/Source_Sans_Pro/SourceSansPro-Medium.ttf");
    font-weight: 500;
    font-display: swap;
} */
@font-face {
    font-family: "Source Sans Pro";
    src: url("/fonts/Source_Sans_Pro/SourceSansPro-Regular.ttf");
    font-weight: 400;
    font-display: swap;
}
@font-face {
    font-family: "Work Sans";
    src: url("/fonts/Work_Sans/WorkSans-Bold.ttf");
    font-weight: 700;
    font-display: swap;
}
@font-face {
    font-family: "Work Sans";
    src: url("/fonts/Work_Sans/WorkSans-SemiBold.ttf");
    font-weight: 600;
    font-display: swap;
}
@font-face {
    font-family: "Work Sans";
    src: url("/fonts/Work_Sans/WorkSans-Medium.ttf");
    font-weight: 500;
    font-display: swap;
}
@font-face {
    font-family: "Work Sans";
    src: url("/fonts/Work_Sans/WorkSans-Regular.ttf");
    font-weight: 400;
    font-display: swap;
}
@font-face {
    font-family: "Archivo";
    src: url("/fonts/Archivo/Archivo-Bold.ttf");
    font-weight: 700;
    font-display: swap;
}
@font-face {
    font-family: "Archivo";
    src: url("/fonts/Archivo/Archivo-SemiBold.ttf");
    font-weight: 600;
    font-display: swap;
}
@font-face {
    font-family: "Archivo";
    src: url("/fonts/Archivo/Archivo-Medium.ttf");
    font-weight: 500;
    font-display: swap;
}
@font-face {
    font-family: "Archivo";
    src: url("/fonts/Archivo/Archivo-Regular.ttf");
    font-weight: 400;
    font-display: swap;
}
/* @font-face {
    font-family: "Inter";
    src: url("/fonts/Inter/Inter-VariableFont_slnt,wght.ttf");
    font-weight: 400;
    font-display: swap;
} */
@font-face {
    font-family: "Google Sans";
    src: url("/fonts/Google_Sans/GoogleSans-Bold.ttf");
    font-weight: 700;
    font-display: swap;
}
@font-face {
    font-family: "Google Sans";
    src: url("/fonts/Google_Sans/GoogleSans-Medium.ttf");
    font-weight: 500;
    font-display: swap;
}
@font-face {
    font-family: "Google Sans";
    src: url("/fonts/Google_Sans/GoogleSans-Regular.ttf");
    font-weight: 400;
    font-display: swap;
}

@font-face {
    font-family: "Gotham";
    src: url("/fonts/Gotham-Font/GothamBold.ttf");
    font-display: swap;
}
@font-face {
    font-family: "Raleway";
    src: url("/fonts/Raleway/static/Raleway-Regular.ttf");
    font-display: swap;
}
@font-face {
    font-family: "Raleway";
    src: url("/fonts/Raleway/static/Raleway-ExtraBold.ttf");
    font-display: swap;
    font-weight: 800;
}
@font-face {
    font-family: "Raleway";
    src: url("/fonts/Raleway/static/Raleway-SemiBold.ttf");
    font-display: swap;
    font-weight: 600;
}
@font-face {
    font-family: "Inter";
    src: url("/fonts/Inter/Inter-Regular.ttf");
    font-display: swap;
    font-weight: 400;
}
@font-face {
    font-family: "Inter";
    src: url("/fonts/Inter/Inter-Medium.ttf");
    font-display: swap;
    font-weight: 500;
}
@font-face {
    font-family: "Inter";
    src: url("/fonts/Inter/Inter-SemiBold.ttf");
    font-display: swap;
    font-weight: 600;
}
@font-face {
    font-family: "Inter";
    src: url("/fonts/Inter/Inter-Bold.ttf");
    font-display: swap;
    font-weight: 700;
}
@font-face {
    font-family: "Inter";
    src: url("/fonts/Inter/Inter-ExtraBold.ttf");
    font-display: swap;
    font-weight: 800;
}
@font-face {
    font-family: "Degular Display";
    src: url("/fonts/Degular Display/DegularDisplay-Semibold.otf");
    font-display: swap;
    font-weight: 600;
}
@font-face {
    font-family: "Degular Variable";
    src: url("/fonts/Degular Variable/DegularVariable.ttf");
    font-display: swap;
}
